import { createUseStyles } from 'react-jss';
import { isIE } from '../../utils/app.utils';
import { getRem } from '@dovera/design-system';

export default createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    zIndex: 500,
    position: 'fixed',
    top: getRem(120),
    right: getRem(6),
    maxHeight: '80vh',
    overflowY: 'hidden',
    overflowX: 'hidden',
    transitionTimingFunction: 'ease-in-out',
    transition: '0.1s',
  },
  expandedRoot: {
    display: 'flex',
    width: `calc(100% - ${getRem(280)} - ${getRem(60)})`,
    flexDirection: 'row',
    alignItems: 'flex-start',
    zIndex: 999,
    position: 'fixed',
    top: getRem(56),
    paddingTop: getRem(24),
    right: getRem(30),
    maxHeight: 'auto',
    overflowY: 'hidden',
    overflowX: 'hidden',
    transitionTimingFunction: 'ease-in-out',
    transition: '0.1s',
    background: '#f7f7f7',
  },
  floatingButton: {
    backgroundColor: '#50a028',
    borderRadius: `${getRem(5)} 0 0 ${getRem(5)}`,
    cursor: 'pointer',
    color: 'white',
    fontSize: getRem(16),
    fontWeight: 'normal',
    top: getRem(200),
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: getRem(40),
    height: getRem(120),
    '& p': {
      transform: 'rotate(270deg)',
      margin: isIE ? getRem(-24) : 0,
      whiteSpace: 'nowrap',
      fontSize: getRem(16),
    },
    '& svg': {
      transform: 'rotate(450deg)',
    },
    '& span': {
      transform: 'rotate(270deg)',
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        transform: 'rotate(90deg)',
        marginLeft: getRem(10),
        width: getRem(10),
      },
    },
  },
  floatingButtonTitle: {
    '& svg': {
      marginLeft: getRem(16),
    },
  },
  content: {
    backgroundColor: 'white',
    width: getRem(520),
    borderRadius: `0 0 0 ${getRem(5)}`,
    boxShadow: `0 0 ${getRem(24)} rgba(0, 0, 0, 0.1)`,
    marginBottom: getRem(35),
  },
  expandedContent: {
    backgroundColor: 'transparent',
    width: '100%',
    maxWidth: 1100,
    margin: '0 auto',
    height: '100vh',
    borderRadius: `0 0 0 ${getRem(5)}`,
  },
  contentBody: {
    height: 'auto',
  },
  extendedContentBody: {
    height: `calc(100vh - ${getRem(110)})`,
    background: '#FFFFFF',
    borderRadius: 5,
  },
});
