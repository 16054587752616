import { Loader, Notification, Tag } from '@dovera/design-system';
import { useSelector } from 'react-redux';
import DDSDatepicker from '../../../../../../components/DDSDatepicker/DDSDatepicker';
import strings from '../../../../../../constants/strings';
import { useAppDispatch } from '../../../../../../hooks/useStore';
import { RootState } from '../../../../../../rootReducer';
import { storeStep3 } from '../../../../../../slices/spaProposals.slice';
import {
  DATE_FORMAT_SLOVAK,
  calculateAge,
  getMoment,
} from '../../../../../../utils/date.utils';
import { cx } from '../../../../../../utils/exports';
import {
  getEventTypeLabel,
  getTxsControlNtfs,
  renderRadioEl,
  spaTxsCheckTypes,
} from '../../../../../../utils/spaProposals.utils';
import useStyles from '../../../../Proposals.styles';
import Reason from './Reason';

const texts = strings.proposals.new;

interface Props {
  confirmationField: any;
  contraindications?: string[];
  contraindicationsField: any;
  epicrisisField: any;
  eventDateField: any;
  examinations?: string[];
  examinationsField: any;
  svlzField: any;
}

const FormElements = ({
  confirmationField,
  contraindications,
  contraindicationsField,
  epicrisisField,
  eventDateField,
  examinations,
  examinationsField,
  svlzField,
}: Props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const {
    step2: { poiData },
    step3,
  } = useSelector((state: RootState) => state.spaProposals.new.stepper);
  const {
    controls,
    formData: { data },
  } = useSelector((state: RootState) => state.spaProposals.new.data);

  const renderTXSControls = getTxsControlNtfs(
    controls.data,
    data?.udajParUdalIndSkup?.perioda || 0,
  ) || <span />;

  const isLoadingTxsControls: boolean =
    !!(
      controls.isLoadingControl &&
      spaTxsCheckTypes.some((s) => controls.isLoadingControl?.includes(s))
    ) ||
    !!(
      data?.udajParUdalIndSkup?.udalost &&
      eventDateField.input.value &&
      !eventDateField.meta.error &&
      controls.data.length < 3
    );

  const renderDatepicker = data &&
    getEventTypeLabel(data.udajParUdalIndSkup?.udalost) && (
      <div className={cx(classes.datepicker, 'mb')}>
        <DDSDatepicker
          {...eventDateField.input}
          error={eventDateField.meta.touched && eventDateField.meta.error}
          id="step3-date"
          isDisabled={isLoadingTxsControls}
          isRequired
          label={getEventTypeLabel(data.udajParUdalIndSkup.udalost)}
          maxDate={new Date()}
          minDate={getMoment().subtract(2, 'years').toDate()}
          pikadayOptions={{
            format: DATE_FORMAT_SLOVAK,
          }}
        />
      </div>
    );

  return (
    <>
      {calculateAge(poiData?.dateOfBirth) >= 75 &&
        renderRadioEl({
          field: confirmationField,
          tooltipClass: classes.radioTooltip,
          withNotificationClass: classes.radioWithNotification,
          wrapperClass: classes.radiocheckWrapper,
          value: confirmationField.input.value,
          id: 'proposals--step3-confirmation',
          label: texts.labels.proposalConfirmation,
          type: 'Áno/Nie',
          tooltip: texts.tooltips.moreThan70,
          notification:
            (step3.spaConfirmation === 'N' && {
              message: texts.notifications.errors.missingConfirmations,
              type: 'error',
            }) ||
            undefined,
          onChange: (value) => dispatch(storeStep3({ spaConfirmation: value })),
        })}
      <>
        {(!step3.showCancelBtnAfter ||
          !['spaConfirmation'].includes(step3.showCancelBtnAfter)) &&
          renderDatepicker}
        {isLoadingTxsControls || !data || controls.error ? (
          controls.error ? (
            <Notification message={controls.error} variant="error" />
          ) : (
            <Loader size={24} />
          )
        ) : (
          <>
            {renderTXSControls}
            {(!step3.showCancelBtnAfter ||
              !['spaTXSControls', 'spaConfirmation'].includes(
                step3.showCancelBtnAfter,
              )) &&
              (!getEventTypeLabel(data.udajParUdalIndSkup?.udalost) ||
                eventDateField.input.value) &&
              renderRadioEl({
                field: contraindicationsField,
                withNotificationClass: classes.radioWithNotification,
                wrapperClass: classes.radiocheckWrapper,
                value: contraindicationsField.input.value,
                id: 'proposals--step3-contraindications',
                label: texts.labels.contraindications,
                type: 'Nemá/Má',
                help: (
                  <ul>
                    {contraindications?.map((c, key) => (
                      <li key={`contraindication--${key}`}>{c}</li>
                    ))}
                  </ul>
                ),
                onChange: (value) =>
                  dispatch(storeStep3({ spaContraindications: value })),
                notification:
                  (step3.spaContraindications === 'Y' && {
                    message: texts.notifications.errors.hasContraindications,
                    type: 'error',
                  }) ||
                  undefined,
              })}
            {(!step3.showCancelBtnAfter ||
              ['spaExaminations', 'spaSVLZ', 'spaTXSControls'].includes(
                step3.showCancelBtnAfter,
              )) &&
              step3.spaContraindications &&
              renderRadioEl({
                field: examinationsField,
                withNotificationClass: classes.radioWithNotification,
                wrapperClass: classes.radiocheckWrapper,
                value: examinationsField.input.value,
                id: 'proposals--step3-examinations',
                label: texts.labels.examinations,
                type: 'Áno/Nie',
                help: (
                  <div className={classes.examinationTags}>
                    {examinations?.map((e, key) => (
                      <Tag key={`step3--examination--${key}`}>{e}</Tag>
                    ))}
                  </div>
                ),
                notification:
                  (step3.spaExaminations === 'N' && {
                    message: texts.notifications.errors.missingExaminations,
                    type: 'error',
                  }) ||
                  (step3.spaExaminations === 'Y' && {
                    message: texts.notifications.info.copyOfExaminations,
                    type: 'info',
                  }) ||
                  undefined,
                onChange: (value) =>
                  dispatch(storeStep3({ spaExaminations: value })),
              })}
            {(!step3.showCancelBtnAfter ||
              step3.showCancelBtnAfter === 'spaSVLZ') &&
              step3.spaExaminations &&
              renderRadioEl({
                field: svlzField,
                id: 'proposals--step3-svlz',
                withNotificationClass: classes.radioWithNotification,
                wrapperClass: classes.radiocheckWrapper,
                value: svlzField.input.value,
                label: texts.labels.SVLZ,
                type: 'Áno/Nie',
                notification:
                  (step3.spaSVLZ === 'N' && {
                    message:
                      texts.notifications.errors.missingYoungerExaminations,
                    type: 'error',
                  }) ||
                  undefined,
                onChange: (value) => dispatch(storeStep3({ spaSVLZ: value })),
              })}
            {!step3.showCancelBtnAfter && step3.spaSVLZ && (
              <Reason epicrisisField={epicrisisField} />
            )}
          </>
        )}
      </>
    </>
  );
};

export default FormElements;
