import React from 'react';
import { Icon, Tooltip } from '@dovera/design-system';

interface Props {
  text: string;
  tooltip: string;
}

const TextWithTooltip = ({ text, tooltip }: Props) => (
  <>
    <span>{text} </span>
    <Tooltip dialog={tooltip}>
      <Icon className="text-color-grey" name="info" />
    </Tooltip>
  </>
);

export default TextWithTooltip;
