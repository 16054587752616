import { ReactNode } from 'react';
import routes from '../../routes';
import { EventType } from '../../types/spaProposals.types';
import { inlineStrCondition } from '../../utils/app.utils';

export const strings = {
  undefined: '-',
  dlekUnavailabillity:
    '<b>Nedostupnosť služieb DôveraLekárom a Elektronickej pobočky.</b>',
  systemNotification: (from: string, to: string) =>
    `<b>Od ${from} do ${to}</b> budeme pracovať na zlepšovaní aplikácie DôveraLekárom.<br />Niektoré funkcionality tak nebudú dostupné.`,
  filterNoDataDefault: 'Nenašli sme žiadne výsledky',
  defaultError: 'Prepáčte, nastala technická chyba na našej strane.',
  technickaChyba: 'Prepáčte, nastala technická chyba<br />na našej strane.',
  nepripojene:
    'Zdá sa, že nemáte pripojenie na internet. Zmeny, ktoré spravíte, nebudú uložené.',
  loginPublicChyba:
    'Prihláste sa, aby ste si mohli bezpečne<br />pozerať všetky údaje',
  loginPublicChybaPopis:
    'Z technických a bezpečnostných dôvodov Vám odporúčame prihlásiť sa.',
  notificationPublic:
    'Po prihlásení si môžete pozrieť aj detail parametrov a inú odbornosť.',
  adresaTooltip:
    'DÔVERA zdravotná poisťovňa, a.s.<br />' +
    'Cintorínska 5<br />' +
    '949 01 Nitra',
  emptyStates: {
    technicalError:
      'Nastala technická chyba. Skúste, prosím, opakovať akciu neskôr.',
    notAllowed: 'Nemáte oprávnenie na zobrazenie tejto stránky.',
    app404: 'Stránka sa nenašla.',
    dataOdbornost: 'Pre danú sekciu sme nenašli dáta k vybranej odbornosti.',
  },
  automatickeOdhlasenie: {
    nadpis: 'Automatické odhlásenie',
    zostavajuciCas: 'Zostávajúci čas do odhlásenia:',
    pokracovat: 'Zostať prihlásený',
    odhlasitSa: 'Odhlásiť sa',
  },
  vseobecne: {
    upravit: 'Upraviť',
    dozvedietSaViac: 'Dozvedieť sa viac',
    prejstNaFormular: 'Prejsť na formulár',
    tlacit: 'Vytlačiť prehľad parametrov',
    tlacitIETooltip:
      'Pre tlač prehľadu parametrov, použite, prosím iný prehliadač, napríklad Chrome.',
    vitajte: 'Vitajte',
    predchadzajucePlnenie: {
      1: 'Plnenie ',
      2: 'v minulosti',
      tooltip: 'Obdobie je uzavreté a údaje sa už nedajú ovplyvniť.',
    },
    vyhodnotene: {
      1: 'Vyhodnotené plnenie',
      tooltip: 'Obdobie je uzavreté a údaje sa už nedajú ovplyvniť.',
    },
    aktualnePlnenie: {
      1: 'Priebežné ',
      2: 'plnenie',
      tooltip: 'Do konca obdobia zostáva <strong>{{dni}} dní</strong>.',
    },
    buduceObdobie: {
      1: 'Výmena parametra pre ',
      2: 'budúce obdobie',
      tooltip: ' Tooltip pre budúce obdobie... ',
    },
    splnene: 'Splnené',
    nesplnene: 'Nesplnené',
    ciastocneSplnene: 'Čiastočne splnené',
    nepovolenyPristup: {
      nadpis: 'Neoprávnený prístup',
      popisPracujeme:
        'Táto sekcia je momentálne nedostupná. Skúste neskôr, prosím.',
      popis:
        'Pre prístup do tejto sekcie nemáte oprávnenie. Pre viac informácii nás <a href="{{href}}" target="_blank">kontaktujte</a>, prosím.',
    },
    ziadneData:
      'Ospravedlňujeme sa. Pre túto sekciu sa nenačítali žiadne dáta. Skúste to neskôr, prosím.',
  },
  prehladPacientov: {
    diagnozy: {
      ziadneDiagnozy:
        'Diagnózy sú zobrazované len pre konkrétnu odbornosť (vyberte odbornosť v hornej lište)',
      pocet: {
        title: 'Počet pacientov',
        titleDoctor: 'Počet pacientov',
        tooltip: 'Pacienti, ktorých ste diagnostikovali na uvedenú diagnózu',
      },
      pocetSR: {
        title: 'Počet pacientov',
        tooltip:
          'Pacienti, ktorých diagnostikovali kolegovia vo Vašej odbornosti na celom Slovensku. Výberom okresu alebo kraja viete porovnať počet diagnostikovaných pacientov pre akýkoľvek okres alebo kraj.',
      },
      podiel: {
        title: 'Podiel ku všetkým Vašim pacientom',
        titleDoctor: 'Podiel ku všetkým Vašim pacientom',
        tooltip:
          '% pacientov s danou diagnózou voči ostatným, ktoré ste diagnostikovali vo Vašej ambulancii',
      },
      podielSR: {
        title: 'Podiel ku všetkým pacientom v rámci SR',
        tooltip:
          '% pacientov s danou diagnózou voči ostatným, ktoré diagnostikovali Vaši kolegovia v danej odbornosti',
      },
      vyskytDiagnoz: {
        title: 'Pre porovnanie vyberte kraj alebo okres:',
        tooltip:
          'Výberom okresu alebo kraja viete porovnať počet diagnostikovaných pacientov aj voči priemeru pre akýkoľvek okres alebo kraj.',
      },
    },
    pacienti: {
      POC_OSET: {
        title: 'Ošetrení unikátni pacienti',
        tooltip:
          'Pacienti poistení v SR, ktorých ste ošetrili za uvedené obdobie. Ak Vašu ambulanciu pacient navštívil viackrát, je uvedený len raz. Ak máte vo svojej ambulancii evidovaných aj kapitovaných poistencov, títo nie sú započítaní ako unikátni ošetrení pacienti.',
      },
      POC_OSET_EU: {
        title: 'Ošetrení unikátni pacienti (EÚ)',
        tooltip:
          'Pacienti poistení v EÚ. Ak Vašu ambulanciu pacient navštívil viackrát, je uvedený len raz.',
      },
      POC_KONT: {
        title: 'Kontakt s unikátnymi pacientami',
        tooltip:
          'Osobné návštevy, telefonické konzultácie, t.j. všetky interakcie s pacientami kedy bola vykázaná ZS za uvedené obdobie.',
      },
      POC_NOVODIAG: {
        title: 'Novodiagnostikovaní pacienti',
        tooltip:
          'Pacienti, ktorí boli  Vami diagnostikovaní na novú chorobu za obdobie posledného roka a súčasne nemali žiadny kontakt s lekárom, napríklad vyšetrenie alebo telefonickú konzultáciu na danú diagnózu za obdobie posledné 2 roky.',
      },
      POC_NAROC: {
        title: 'Najdrahší pacienti',
        tooltip:
          'Pacienti, ktorých zdravotný stav spôsobuje vysoké náklady. Títo pacienti sa nezohľadňujú ani pri parametri: "Náklady na ZS" ak sa pre danú ambulanciu vypočítava.',
      },
      POC_KAPIT: {
        title: 'Kapitovaní pacienti',
        tooltip:
          'Pacienti, ktorí sú evidovaní vo Vašej ambulancii s podpísanou dohodou.',
      },
      IR: {
        title: 'Index rizika pacienta',
        tooltip:
          'Hodnota na základe, ktorej je možné porovnať rizikovosť pacienta. Pacient s priemerným rizikom má hodnotu 1,0. Ak je hodnota nižšia ako 1,0 máte pacientov s menším rizikom a ak je vyššia ako 1,0 tak s vyšším rizikom.',
      },
      prehlad: {
        ZS: {
          title: 'Čerpanie zdravotnej starostlivosti',
          titleFilter: 'Čerpanie zdravotnej starostlivosti',
          ZS_HOSP: {
            title: 'Hospitalizovaní pacienti',
            description: () =>
              'Tu zobrazujeme Vašich pacientov, ktorí v poslednom roku absolvovali aspoň 1 hospitalizáciu.',
            tooltip:
              'Pacienti, ktorých ste ošetrili a zároveň boli hospitalizovaní za uplynulé obdobie. ',
          },
          POC_NAROC: {
            title: 'Najdrahší pacienti',
            description: () =>
              'Tu si môžete pozrieť Vašich najnákladnejších pacientov. Zobrazujeme 50 Vašich pacientov v poradí od najdrahšieho.',
            tooltip:
              'Pacienti s najvyššími nákladmi, ktorých ošetrujete a ktorým indikujete ďalšiu zdravotnú starostlivosť',
            tooltipCard1:
              'Informatívna suma za celkové náklady na pacienta, ktoré ste vytvorili (Vaše a odporúčané) za lieky, SVLZ a výkony. Táto suma nevstupuje do výpočtu hodnotiacich parametrov.',
            tooltipCard2:
              'Suma po očistení 2% najdrahších pacientov a po odpočítaní vylúčených výkonov, napríklad preventívne prehliadky, očkovanie, ...)',
          },
          ZS_NEOCK: {
            title: 'Pacienti nezaočkovaní proti covidu-19',
            description: (obdobieOd: string, obdobieDo: string) =>
              obdobieOd && obdobieDo
                ? `Informácie o zaočkovaných pacientoch dostávame do poisťovne s mesačným oneskorením a aktualizujeme ich do 10. dňa v mesiaci. Napríklad: Ak bol pacient očkovaný v máji, do prehľadu sa táto informácia dostane po 10. júli. Údaje o rizikových pacientoch sú vyhodnotené za obdobie <b>${obdobieOd}</b> až <b>${obdobieDo}</b>. Definíciu diagnózy, ktorá môže zvýšiť riziko závažného priebehu covidu-19 nájdete vo <a href="https://www.slov-lex.sk/static/pdf/2021/96/ZZ_2021_96_20210605.pdf" target="_blank">vyhláške</a>.`
                : '',
            tooltip:
              'Nezaočkovaní pacienti podľa vekových skupín a diagnózy, ktorá môže zvýšiť riziko závažného priebehu covidu-19.',
          },
          ZS_POS_COV: {
            title: 'Pacienti vhodní na podanie monoklonálnych protilátok',
            description: () =>
              'Na základe vykázaného laboratórneho vyšetrenia (PCR test) môžu títo pacienti spĺňať <b>indikačné kritéria na liečbu monoklonálnymi protilátkami (MAB).</b><br /><br />Zobrazujeme pacientov:<br /><ul class="no-mrg-bottom"><li>nad 65 rokov bez ohľadu na pridružené diagnózy,</li><li>mladších ako 65 rokov, ktorí majú diagnózu spôsobujúcu ťažký priebeh covidu-19.</li></ul><br />Predtým, ako pacientovi vystavíte výmenný lístok a objednáte ho na podanie MAB, <b>skontrolujte, prosím, jeho zdravotný stav</b>, či spĺňa indikačné kritériá na podanie MAB.<br /><br /><b>Dopravnú zdravotnú službu</b> je možné poskytnúť pacientom, ktorým zdravotný stav neumožňuje si zabezpečiť vlastnú dopravu do centra.',
            tooltip:
              'Vaši kapitovaní pacienti, ktorým môže byť indikované podanie liečby monoklonálnymi protilátkami.',
          },
        },
        CHR: {
          title: 'Chronickí pacienti vo Vašom kmeni',
          titleFilter: 'Chronickí pacienti',
          CHR_HYP: {
            title: 'Diagnostikovaní hypertonici',
            description: () =>
              'Nižšie nájdete podiel hypertonikov vo Vašom kmeni a informáciu o rozdelení starostlivosti o nich medzi Vás a lekárov - špecialistov.',
            tooltip:
              'Informácie o pacientoch s hypertenziou vrátane informácie o farmakoterapii a prípadných komorbiditách pacienta',
          },
          CHR_HYP_STAR: {
            title: 'Hypertonici s možnou zmenou v starostlivosti',
            description: () =>
              'Pri týchto pacientoch sme indentifikovali možnosť presunu starostlivosti medzi Vami a lekárom - špecialistom. Zvážte, kedy je vhodná liečba pacienta výhradne vo Vašej starostlivosti a kedy je potrebná spolupráca so špecialistom.',
            tooltip:
              'Informácie o štruktúre pacientov s hypertenziou vo Vašom kmeni podľa komorbidít a o rozložení starostlivosti medzi Vás a lekárov ŠAS.',
          },
          CHR_DIA: {
            title: 'Diagnostikovaní diabetici',
            description: () =>
              'Nižšie nájdete prehľad diabetikov vo Vašej starostlivosti a informácie o priebehu edukácie pacientov.',
            tooltip:
              'Informácie o pacientoch s diabetom vrátane informácie o potrebe edukácie pacienta',
          },
          CHR_DISP: {
            title: 'Dispenzarizovaní pacienti',
            description: () =>
              'Na tejto stránke nájdete prehľad Vašich dispenzarizovaných pacientov. Na ľavo môžete vyhľadať konkrétneho pacienta alebo vybrať zoznam pacientov podľa diagnózy, s ktorou sú dispenzarizovaní.',
            tooltip:
              'Informácie o všetkých dispenzarizovaných pacientoch, ktorých máte vo svojej starostlivosti',
          },
          CHR_GDM: {
            title: 'Gestačné diabetičky',
            description: (text: string) =>
              `Tu môžete vidieť prehľad gestačných diabetičiek, u ktorých od pôrodu neevidujeme kontrolu (oGTT, HbA1c alebo vyšetrenie glykémie) a sú vo vyššom riziku rozvoja diabetu 2.typu, po ktorom treba pátrať celoživotne. Údaje sú aktualizované k ${text}.`,
            tooltip:
              'Tehotné pacientky s rizikom rozvoja diabetu 2. typu a prediabetu.',
            tooltipDiscard: 'Pacientku môžete zo zoznamu vyradiť.',
            archiv: {
              description:
                'Po vyradení už pacientku v zozname neuvidíte a nemôžete ho vrátiť späť.',
              title: 'Naozaj chcete pacientku vyradiť?',
            },
          },
        },
        FARM: {
          title: 'Farmakoterapia Vašich pacientov',
          titleFilter: 'Farmakoterapia',
          FARM_POSUD: {
            title: 'Pacienti s klinickým posudkom',
            description:
              'Pacienti, ktorým bola počas hospitalizácie prehodnotená farmakoterapia. Odporúčania na úpravu medikácie nájdete v prepúšťacej správe pacienta.',
            tooltip:
              'Pacienti, ktorým bola počas hospitalizácie prehodnotená farmakoterapia. Odporúčania na úpravu medikácie nájdete v prepúšťacej správe pacienta.',
            tooltipDiscard: 'Pacienta môžete zo zoznamu vyradiť.',
            archiv: {
              description:
                'Po vyradení už pacienta v zozname pacientov s klinickým posudkom neuvidíte a nemôžete ho vrátiť späť.',
              title: 'Naozaj si želáte pacienta vyradiť?',
            },
          },
          FARM_PROB01: {
            title: 'Pacienti s medikačným problémom',
            description:
              'Pacienti s možným klinickým problémom v preskripcii liekov. Viac o probléme a možnostiach riešenia nájdete v odbornom usmernení Inhibítory protónovej pumpy – nesprávna indikácia.',
            tooltip:
              'Pacienti s možným klinickým problémom v preskripcii liekov.',
            tooltipDiscard: 'Pacienta môžete zo zoznamu vyradiť.',
            archiv: {
              description:
                'Po vyradení už pacienta v zozname pacientov s medikačným problémom neuvidíte a nemôžete ho vrátiť späť.',
              title: 'Naozaj si želáte pacienta vyradiť?',
            },
          },
          FARM_HYP_NESP: {
            title: 'Hypertonici s neštandardnou farmakoterapiou',
            description:
              'V tejto časti nájdete informácie o hypertonikoch bez komorbidít alebo s diabetom, pri ktorých môže nastávať nesúlad farmakoterapie so štandardom.',
            tooltip:
              'Informácie o hypertonikoch bez komorbidít alebo s diabetom, pri ktorých môže nastávať nesúlad farmakoterapie so štandardom.',
          },
          FARM_HYP_NEVH: {
            title: 'Hypertonici s možnou nevhodnou kombináciou liekov',
            description:
              'Pri týchto pacientoch môže dochádzať k nevhodným, resp. kontraindikovaným kombináciám liekov vo farmakoterapii.',
            tooltip:
              'Informácie o hypertonikoch, pri ktorých môže dochádzať k nevhodnej kombinácii liekov vo farmakoterapii.',
          },
          FARM_POLY: {
            title: 'Pacienti s väčším počtom užívaných liekov',
            description:
              'Pacienti, ktorí užívajú dlhodobo viacero druhov liekov. Úpravou preskripcie je možné znížiť riziká ako sú interakcie, kontraindikácie a nežiadúce účinky.<br /><br />U pacientov označených „Pacient s klinickým posudkom” farmakoterapiu prehodnotil klinický farmakológ/farmaceut počas hospitalizácie. Odporúčania k úprave medikácie nájdete v prepúšťacej správe.',
            tooltip:
              'Pacienti, ktorí užívajú vyšší počet liekov a bolo by vhodné prehodnotiť užívané lieky.',
          },
          FARM_NEVYB: {
            title: 'Nevybrané lieky ',
            description:
              'Tu si môžete pozrieť informácie o pacientoch, o ktorých evidujeme, že si v lekárni nevybrali Vami predpísané lieky. Pacienti sú zobrazení od posledného predpisu lieku. V prípade, že v zozname nenájdete pacienta, ktorého hľadáte, môžete použiť vyhľadávanie cez meno alebo rodné číslo pacienta. V zozname liekov sa môžu nachádzať aj lieky, ktoré pacient vybral, avšak lekáreň informáciu o ich výbere neposkytla zdravotnej poisťovni.',
            tooltip:
              'Pacienti, o ktorých evidujeme, že si nevybrali predpísaný liek a tým pádom by mohla byť ovplyvnená ich následná liečba',
          },
          FARM_ZAM: {
            title: 'Zamenený liek v lekárni',
            description:
              'Tu si môžete pozrieť informácie o liekoch, ktoré boli Vašim pacientom v lekárni zamenené za iný liek. Obsahuje zoznam liekov, ktoré sú predpisované lekárom, avšak lekáreň ich, napríklad z dôvodu aktuálneho nedostatku liekov alebo iných okolností, zamenila za iný liek. V tomto zozname však nie sú uvedené lieky, ktoré sa zamieňajú v rámci predpisu na základe účinnej látky (generická substitúcia).',
            tooltip:
              'Pacienti, ktorým aj napriek predpisu konkrétneho lieku bol zamenený v lekárni iný liek.',
          },
        },
      },
    },
  },
  diagnozy: {
    filter: {
      mostExpensive: {
        label: 'Najdrahšie diagnózy',
        tooltip:
          'Voľba „Všetky diagnózy“ zobrazí<br />Vaše náklady za všetky vykázané<br />diagnózy, v rámci vybranej<br />odbornosti. Voľba „Najdrahšie<br />diagnózy“ zobrazí Vaše náklady na<br />top 10 najdrahších diagnóz, v rámci<br />vybranej odbornosti.',
      },
    },
    tipy: {
      nadpis: 'Vysvetlenie pojmov a tipy na prácu s prehľadom nákladov.',
      popis:
        '<li><b>Vaša hodnota</b> je náklad na lieky, zdravotnícke pomôcky, zdravotné výkony a SVLZ výkony pre jedného pacienta vo zvolenej diagnóze za dané obdobie. Hodnota je v eurách (€).</li><li><b>Referencia</b> (REF.:) je priemerný náklad v eurách, ktorý majú na pacienta ostatní lekári rovnakej odbornosti. Hodnota je vypočítaná vždy pre zvolenú diagnózu a za obdobie uvedené v grafe.</li><li>V grafoch vidíte porovnanie svojej hodnoty nákladov s referenciou.</li><li>Pre porovnanie s referenciou vo Vašom kraji zvoľte vo filtri možnosť <b>Kraj</b>.</li><li>Diagnózy sú vo filtri zoradené podľa frekvencie výskytu u Vašich pacientov. Prehľad o inej diagnóze zvolíte kliknutím na jej názov vo filtri. Diagnózu si môžete aj vyhľadať podľa názvu alebo kódu.</li><li>Detailný prehľad nákladov na lieky, zdravotnícke pomôcky, zdravotné výkony či SVLZ výkony nájdete po kliknutí na <b>Detail</b>.</li>',
      popisDetail:
        '<li><b>Vaša hodnota</b> je náklad na lieky, zdravotnícke pomôcky, zdravotné výkony a SVLZ výkony pre jedného pacienta vo zvolenej diagnóze za dané obdobie. Hodnota je v eurách (€).</li><li><b>Referencia</b> (REF.:) je priemerný náklad v eurách, ktorý majú na pacienta ostatní lekári rovnakej odbornosti. Hodnota je vypočítaná vždy pre zvolenú diagnózu a za obdobie uvedené v grafe.</li><li>V grafoch vidíte porovnanie svojej hodnoty nákladov s referenciou.</li><li>Pre porovnanie s referenciou vo Vašom kraji zvoľte vo filtri možnosť <b>Kraj</b>.</li><li>Diagnózy sú vo filtri zoradené podľa frekvencie výskytu u Vašich pacientov. Prehľad o inej diagnóze zvolíte kliknutím na jej názov vo filtri. Diagnózu si môžete aj vyhľadať podľa názvu alebo kódu.</li><li>Detailný prehľad nákladov nájdete po kliknutí na <b>Detail</b>.</li>',
      extraVAS: {
        L: '<li>Filter <b>Vami predpísané</b> obsahuje lieky predpísané Vami. Lieky, ktoré ste predpísali na odporúčanie iného poskytovateľa sa nezapočítavajú do Vašich nákladov, preto nie sú zahrnuté.</li><li>Filter <b>Predpísané iným poskytovateľom</b> obsahuje lieky predpísané iným poskytovateľom primárnej starostlivosti na Vašich kapitovaných pacientov. Započítavajú sa do Vašich nákladov.</li>',
        S: '<li>Filter <b>Vami indikované</b> obsahuje SVLZ výkony indikované Vami. SVLZ výkony, ktoré ste predpísali na odporúčanie iného poskytovateľa sa nezapočítavajú do Vašich nákladov, preto nie sú zahrnuté.</li><li>Filter <b>Indikované iným poskytovateľom</b> obsahuje SVLZ vyšetrenia indikované iným poskytovateľom primárnej starostlivosti na Vašich kapitovaných pacientov. Započítavajú sa do Vašich nákladov.</li>',
      },
      extraSAS: {
        L: '<li>Filter <b>Predpísané na Vaše odporúčanie</b> obsahuje lieky, ktoré ste odporučili a predpísal ich iný lekár.</li>',
        S: '<li>Filter <b>Indikované na Vaše odporúčanie</b> obsahuje výkony, ktoré ste odporučili a indikoval ich iný lekár.</li>',
      },
    },
    tooltipy: {
      null: 'Porovnanie Vašich celkových nákladov na pacienta pre túto diagnózu v danej odbornosti.',
      L: 'Porovnanie nákladov (€) <b>na predpísané lieky - ambulantné aj vydané v lekárni</b> pre zvolenú diagnózu v danom období vo Vašej odbornosti.',
      Z: 'Porovnanie nákladov (€) <b>na predpísané zdravotnícke pomôcky - ambulantné aj vydané v lekárni, vrátane optických pomôcok</b>, pre zvolenú diagnózu v danom období vo Vašej odbornosti.',
      V: 'Porovnanie nákladov (€) <b>na vykázané zdravotné výkony - vlastné aj indikované</b>, pre zvolenú diagnózu v danom období vo Vašej odbornosti.',
      S: 'Porovnanie nákladov (€) <b>na indikované SVLZ výkony - laboratórne, zobrazovacie, iné/liečebné)</b>, pre zvolenú diagnózu v danom období vo Vašej odbornosti.',
      SL: 'Porovnanie nákladov (€) <b>na indikované vyšetrenia</b> pre zvolenú diagnózu v danom období vo Vašej odbornosti.',
      SZ: 'Porovnanie nákladov (€) <b>na indikované vyšetrenia</b> pre zvolenú diagnózu v danom období vo Vašej odbornosti.',
      SI: 'Porovnanie nákladov (€) <b>na indikované vyšetrenia</b> pre zvolenú diagnózu v danom období vo Vašej odbornosti.',
    },
    graf: {
      tooltipy: {
        null: 'Náklady na pacienta na lieky, pomôcky, zdravotné výkony a SVLZ pre túto diagnózu (€).',
      },
    },
    segmenty: {
      nadpis: {
        null: 'Náklady na jedného pacienta pre danú diagnózu',
        L: 'Skupiny liekov s najväčším rozdielom v nákladoch v porovnaní s referenciou',
        Z: 'Skupiny zdravotníckych pomôcok s najväčším rozdielom v nákladoch v porovnaní s referenciou',
        V: 'Skupiny výkonov s najväčším rozdielom v nákladoch v porovnaní s referenciou',
        S: 'Skupiny SVLZ s najväčším rozdielom v nákladoch v porovnaní s referenciou',
        SL: 'Laboratórne vyšetrenia s najväčším rozdielom v nákladoch v porovnaní s referenciou',
        SZ: 'Zobrazovacie vyšetrenia s najväčším rozdielom v nákladoch v porovnaní s referenciou',
        SI: 'Liečebné vyšetrenia s najväčším rozdielom v nákladoch v porovnaní s referenciou',
      },
      tooltipy: {
        L: 'Predpísané lieky - ambulantné, vydané v lekárni.',
        Z: 'Predpísané zdravotnícke pomôcky - ambulantné aj vydané v lekárni, vrátane optických pomôcok.',
        V: 'Vykázané zdravotné výkony - vlastné aj indikované. ',
        S: 'Indikované SVLZ výkony - laboratórne, zobrazovacie, iné/liečebné. ',
      },
      nadpisTooltipy: {
        L: '<b>Pacienti</b> - podiel zo všetkých Vašich pacientov, ktorým bol pri tejto diagnóze predpísaný uvedený liek.<br /><b>Náklady</b> - priemerný náklad  na pacienta, ktorému bol predpísaný daný liek (€).',
        Z: '<b>Pacienti</b> - podiel zo všetkých Vašich pacientov, ktorým boli pri tejto diagnóze predpísané uvedené pomôcky.<br /><b>Náklady</b> - priemerný náklad na pacienta, ktorému bola predpísaná daná pomôcka (€).',
        V: '<b>Pacienti</b> - podiel zo všetkých Vašich pacientov, ktorým boli pri tejto diagnóze vykázané uvedené výkony.',
        S: '<b>Pacienti</b> - podiel zo všetkých Vašich pacientov, ktorým boli pri tejto diagnóze indikované uvedené SVLZ výkony.<br /><b>Náklady</b> - priemerný náklad na pacienta, ktorému boli  indikované dané SVLZ výkony (€).',
        SL: '<b>Pacienti</b> - podiel zo všetkých Vašich pacientov, ktorým boli pri tejto diagnóze indikované uvedené vyšetrenia.<br /><b>Náklad</b> - priemerný náklad na pacienta, ktorému bolo indikované dané vyšetrenie (€).',
        SZ: '<b>Pacienti</b> - podiel zo všetkých Vašich pacientov, ktorým boli pri tejto diagnóze indikované uvedené vyšetrenia.<br /><b>Náklad</b> - priemerný náklad na pacienta, ktorému bolo indikované dané vyšetrenie (€).',
        SI: '<b>Pacienti</b> - podiel zo všetkých Vašich pacientov, ktorým boli pri tejto diagnóze indikované uvedené vyšetrenia.<br /><b>Náklad</b> - priemerný náklad na pacienta, ktorému bolo indikované dané vyšetrenie (€).',
      },
    },
    detail: {
      popis: {
        L: 'V tabuľke zobrazujeme pacientov, ktorým ste predpísali účinnú látku.<br />Pacientov zoraďujeme podľa výšky nákladov na liečbu.',
        Z: 'V tabuľke zobrazujeme pacientov, ktorým ste predpísali vybrané zdravotnícke pomôcky.<br />Pacientov zoraďujeme podľa výšky nákladov na liečbu.',
        V: 'V tabuľke zobrazujeme pacientov, ktorým ste vykázali vybrané zdravotné výkony.<br />Pacientov zoraďujeme podľa výšky nákladov na liečbu.',
        S: 'V tabuľke zobrazujeme pacientov, ktorým ste vykázali vybrané SVLZ výkony.<br />Pacientov zoraďujeme podľa výšky nákladov na liečbu.',
      },
      vyluceniPacienti:
        'V zozname sa nenachádzajú {2 % pacientov s najvyššími nákladmi} na liečbu. Títo pacienti boli výlučení aj z hodnotiacich parametrov a nevstupujú ani do výpočtu tejto referencie.',
    },
  },
  zmluvy: {
    hromadnyPodpis: {
      description:
        'Aby ste mohli podpísať zmluvné dokumenty, musíte si ich najskôr stiahnuť a oboznámiť sa s ich obsahom. Po stiahnutí budete môcť dokumenty podpísať.',
      stepper: {
        step1: {
          title: 'Stiahnuť dokumenty',
          description: (count: number) =>
            `<b>${count}</b> dokument${
              count !== 1
                ? inlineStrCondition(count > 1 && count < 5, 'y', 'ov')
                : ''
            } v .zip súbore je pripravených sa stiahnutie.`,
          button: 'Stiahnuť',
        },
        step2: {
          title: 'Podpísať dokumenty',
          descriptionAsoc: (name?: string, func?: string) =>
            `Zmluvné dokumenty podpisujete ako <b data-hj-masked="true">${name}</b>, <span data-hj-masked="true">${func}</span>.`,
          button: 'Podpísať',
          error:
            'Podmienkou pre podpísanie je vyjadrenie súhlasu s podmienkami.',
        },
      },
    },
    individualnyPodpis: {
      description:
        'Dôvera a Zdravita sa dohodli na predĺžení zmluvných podmienok do 30.6.2021. Dodatkom sa navyšuje základná kapitácia pre prvých ...',
      detailPdf: 'Zobraziť detail (PDF)',
      detailPdfPzs: 'Pozrieť detail zmeny',
      labels: {
        state: 'Stav',
        dateAdded: 'Pridané',
        name: 'Názov poskytovateľa',
        ico: 'IČO',
        date: 'Dátum účinnosti',
        signTo: 'Podpísať do',
      },
      subtitle: (
        cisloDodatku: string | null,
        cisloZmluvy: string | null,
        typDokumentu: string | null,
      ) =>
        typDokumentu !== 'Z'
          ? `Dodatok č. ${cisloDodatku || ''} k zmluve č. ${cisloZmluvy || ''}`
          : `Zmluva č. ${cisloZmluvy || ''}`,
      downloadBtn: 'Stiahnuť',
      signingAs: (name: string, role: string) =>
        `Zmluvný dokument podpisujete ako <b data-hj-masked="true">${name}</b>, <span data-hj-masked="true">${role}</span>.`,
      warning:
        'Pre podpísanie si stiahnite dokument alebo si ho pozrite celý v náhľade.',
      errorInactiveCheckbox:
        'Pred podpísaním si pozrite celý dokument - v náhľade alebo kliknutím na [Zobraziť]',
      error: 'Podmienkou pre podpísanie je vyjadrenie súhlasu s podmienkami.',
      signBtn: 'Podpísať',
      remarkBtn: 'Pripomienkovať',
      remarkTooltip:
        'Možnosť pripomienkovať končí 2 pracovné dni pred dátumom podpisu.',
      chybaKonatel:
        'Nemáte oprávnenie na podpis zmluvnej dokumentácie. Dokumentáciu môže <b>podpisovať iba štatutár/konateľ</b>, ktorému je potrebné <b>vytvoriť prístup</b> v Elektronickej pobočke.',
      chybaStatutar:
        'Nemáte oprávnenie na podpis zmluvnej dokumentácie. Dokumentáciu môže <b>podpisovať iba štatutár/konateľ</b>.',
    },
    spolocne: {
      approval: (dodatok: boolean) =>
        `Zakliknutím tohto poľa potvrdzujete, že ste sa oboznámili s obsahom návrhu ${
          dodatok ? 'dodatku' : 'zmluvy'
        } a porozumeli mu. Kliknutím na “Podpísať” súhlasíte s obsahom návrhu ${
          dodatok ? 'dodatku' : 'zmluvy'
        }, návrh prijímate a vyjadrujete vôľu poskytovateľa zdravotnej starostlivosti byť ustanoveniami ${
          dodatok ? 'dodatku' : 'zmluvy'
        } viazaný.`,
    },
    vylsedokPodpisu: {
      multiple: {
        success: {
          title: 'Dokumenty boli úspešne podpísané',
          description:
            'Na {tomto odkaze} si môžete stiahnuť zoznam podpísaných dokumentov.',
        },
        loading: 'Prebieha podpisovanie zmlúv.<br/ >Počkajte, prosím...',
      },
      single: {
        success: {
          title: 'Dokument bol úspešne podpísaný',
          description: '',
        },
        loading: 'Prebieha podpisovanie zmluvy.<br/ >Počkajte, prosím...',
      },
      error: {
        title: 'Nepodarilo sa podpísať',
        description:
          'Prepáčte, nastala technická chyba na našej strane.<br />Skúste, prosím, podpísať neskôr.',
      },
      errorAlreadySigned: {
        title: 'Nepodarilo sa podpísať',
        description: 'Dokument už bol podpísaný inou osobou.',
      },
      button: 'Ísť na prehľad zmlúv a dodatkov',
    },
    zoznam: {
      tooltipy: {
        vyhladavanie: 'Zadajte IČO alebo názov poskytovateľa',
      },
      ciastocnePodpisana: (dodatok: number | null) =>
        `${dodatok ? 'Dodatok' : 'Zmluva'} je čiastočne ${
          dodatok ? 'podpísaný' : 'podpísaná'
        }.`,
    },
    detail: {
      zobrazitLink: 'Zobraziť zmluvu',
      zobrazitDetail: 'Zobraziť detail (PDF)',
      stiahnutBtn: 'Stiahnuť',
      stiahnutBtnPZS: 'Stiahnuť zmluvu',
      stiahnutPdf: 'Stiahnuť PDF',
      nahladZmluvy: 'Náhľad zmluvy',
      nahladDodatku: 'Náhľad dodatku',
      labels: {
        dodatokPodpisal: 'Dodatok podpísal/a',
        zmluvuPodpisal: 'Podpísal/a',
        podpisane: 'Podpísané',
        datumPodpisu: 'Dátum podpisu',
        datumUcinnosti: 'Dátum účinnosti',
        ucinnostDo: 'Účinné do',
        stav: 'Stav',
        pridane: 'Pridané',
        podpisatDo: 'Podpísať do',
        stornovane: 'Stornované',
      },
      uplynulCas: 'Uplnynul čas na podpis zmluvného dokumentu.',
      stornovane: (dovod: string) => `<b>Stornované</b> z dôvodu: ${dovod}`,
      nazovDokumentu: (
        cisloDodatku: string,
        cisloZmluvy: string,
        idDodatok: number | null,
        typZmluvy: string,
      ) =>
        typZmluvy === 'Z' || (!cisloDodatku && !idDodatok)
          ? `Zmluva č. ${cisloZmluvy}`
          : `Dodatok č. ${cisloDodatku} k zmluve č. ${cisloZmluvy}`,
    },
    emptyStates: {
      zmluvy: 'Nenašli sme žiadne dokumenty v tomto stave',
      zmluvyNaPodpis: 'Aktuálne nemáte žiadne zmluvy alebo dodatky na podpis.',
      dodatky: 'Neevidujeme dodatky k tejto zmluve',
      vyhladavanie: 'Pre hľadaný výraz sme nenašli žiadne výsledky',
      default: 'Nenašli sme žiadne dokumenty',
      ziadneDodadtky: 'Nenašli sme požadovaný dodatok',
      defaultBtn: 'Vrátiť sa na zoznam',
      backToDetailBtn: 'Vrátiť sa na detail zmluvy',
    },
  },
  cookies: {
    title: 'Cookies',
    description:
      'Súbor cookie je malý blok údajov (textový súbor), ktoré sa do vášho prehliadača ukladajú na pokyn internetovej lokality, keď ju používateľ navštívi, s cieľom zapamätať si informácie o vás, ako napríklad preferencie jazyka alebo prihlasovacie údaje. Tieto súbory cookie nastavujeme my a nazývajú sa súbory cookie prvej strany. Používame aj súbory cookie tretích strán. Ide o súbory cookie z inej domény, ako je doména internetovej lokality, ktorú práve navštevujete, a slúžia na účely našich reklamných a marketingových činností. Konkrétnejšie používame súbory cookie a iné sledovacie technológie na tieto účely:',
    necessaryCookies: {
      title: 'Nevyhnutne potrebné súbory cookie',
      description:
        'Tieto súbory cookie sú potrebné na zabezpečenie funkčnosti internetovej stránky a nemožno ich v našich systémoch vypnúť. Zvyčajne sa nastavujú len ako reakcia na vami vykonané činnosti, ktoré predstavujú žiadosť súvisiacu so službami, ako je napríklad nastavenie preferencií ochrany osobných údajov, prihlasovanie alebo vypĺňanie formulárov. Svoj prehliadač môžete nastaviť tak, aby blokoval alebo vás upozorňoval na takéto súbory cookie, v takom prípade však nemusia niektoré časti stránky fungovať.',
      tableTitle: 'Súbory cookies prvých strán',
    },
    functionCookies: {
      title: 'Súbory cookie súvisiace s funkčnosťou',
      description:
        'Tieto súbory cookie zabezpečujú lepšiu funkčnosť a prispôsobenie obsahu, ako sú videá a živý chat. Môžeme ich nastaviť my alebo externí poskytovatelia, ktorých služby sme pridali na naše stránky. Ak tieto súbory cookie nepovolíte, niektoré alebo všetky funkcie nemusia fungovať správne.',
      tableTitleFirstParty: 'Súbory cookies prvých strán',
      tableTitleThirdParty: 'Súbory cookies tretích strán',
    },
    powerCookies: {
      title: 'Súbory cookie súvisiace s výkonom',
      description:
        'Tieto súbory cookie nám umožňujú určiť počet návštev a zdroje návštevnosti, aby sme mohli merať a vylepšovať výkon našej stránky. Pomáhajú nám zistiť, ktoré stránky sú najviac a najmenej populárne, a vidieť, koľko návštevníkov sa na stránke pohybuje. Všetky informácie, ktoré tieto súbory cookie zbierajú, sú súhrnné, a teda anonymné. Ak tieto súbory cookie nepovolíte, nebudeme vedieť, kedy ste našu stránku navštívili.',
      tableTitle: 'Súbory cookies prvých strán',
    },
    targetCookies: {
      title: 'Súbory cookie pre cieľové zameranie',
      description:
        'Tieto súbory cookie nastavujú prostredníctvom našej stránky naši reklamní partneri. Tieto spoločnosti ich môžu používať na zostavenie profilu vašich záujmov a zobrazenie relevantných reklám na iných stránkach. Fungujú tak, že jedinečným spôsobom identifikujú váš prehliadač a zariadenie. Ak tieto súbory cookie nepovolíte, nebudete na iných internetových stránkach dostávať náš cieľový reklamný obsah.',
      tableTitleFirstParty: 'Súbory cookies prvých strán',
      tableTitleThirdParty: 'Súbory cookies tretích strán',
    },
    validityCookies: {
      title: '*Životnosť',
      description:
        'Dočasné cookies (automaticky sú zmazané po zatvorení webového prehliadača).<br />Trvalé cookies (ostanú na disku uložené aj po zatvorení webového prehliadača).',
    },
    settingsButton: 'Nastavenia cookies',
  },
  profile: {
    title: 'Profil',
    labels: {
      ico: 'IČO:',
      kodPZS: 'Kód poskytovateľa:',
      as: 'Adresa sídla:',
      ka: 'Korešpondenčná adresa:',
      asoc: 'Členstvo v asociácii:',
      doveraPomaha: 'Program DôveraPomáha:',
      vztah: 'Zmluvný vzťah:',
      odbornosti: 'Odbornosti:',
      addAddress: 'Pridať adresu',
      editAddress: 'Upraviť adresu',
      countExpertises: (count: number) =>
        `${count} odbornost${count > 4 ? 'í' : 'i'}`,
      position: 'Pozícia:',
      email: 'E-mail:',
      doctorCode: 'Kód lekára:',
    },
    tooltips: {
      as: 'Sídlo spoločnosti ako je zapísané v obchodnom registri.',
      ka: 'Zmenu korešpondenčnej adresy nám nahláste cez Schránku správ v Elektronickej pobočke.',
    },
    links: {
      kontaktneOsoby: 'Kontaktné osoby',
      bankoveSpojenie: 'Bankové spojenie',
      zmluvnePristroje: 'Zmluvné prístroje',
      overeniaPrehlady: 'Overenia a prehľady',
      prihlasovacieUdaje: 'Zmena prihlasovacích údajov',
    },
    modals: {
      expertises: {
        title: 'Odbornosti',
        close: 'Zavrieť',
        labels: {
          code: 'Kód',
          expertise: 'Odbornosť',
        },
      },
      ka: {
        title: (addressExist: boolean) =>
          `${addressExist ? 'Upraviť' : 'Pridať'} korešpondenčnú adresu`,
        labels: {
          country: 'Krajina',
          town: 'Obec',
          zip: 'PSČ',
          street: 'Ulica',
          streetNumber: 'Popisné číslo',
          cancel: 'Zrušiť',
          submit: 'Uložiť zmeny',
        },
      },
    },
    overeniaPrehlady: {
      description:
        'Overenia <a href="https://www.dovera.sk/overenia/overit-poistenca" target="_blank">poistenca</a>, <a href="https://www.dovera.sk/overenia/overit-uvazok-lekara" target="_blank">lekára a jeho úväzku</a> alebo <a href="https://www.dovera.sk/overenia/overit-prevadzku" target="_blank">ambulancie</a> sú dostupné na webe <a href="https://www.dovera.sk" target="_blank">dovera.sk</a>.',
      subtitle: 'Prehľady lekárov a ambulancií',
      baseFilter: [
        {
          text: 'Lekári',
          value: 'L',
        },
        {
          text: 'Ambulancie',
          value: 'A',
        },
      ],
      filterNoData: 'Nenašli sme žiadne výsledky',
      export: 'Exportovať',
      exportTitle: (isAmbulances: boolean) =>
        `Overenia a prehľady - ${isAmbulances ? 'AMBULANCIE' : 'LEKÁRI'}`,
      filter: {
        ambulances: {
          labels: {
            name: 'Kód/názov ambulancie',
            expertise: 'Kód/názov odbornosti',
            contractual: 'Zmluvná',
            date: 'K dátumu',
          },
          columns: {
            name: 'Kód/názov ambulancie',
            expertise: 'Kód/názov odbornosti',
            valid: 'Platná od - do',
            contractual: 'Zmluvná',
            action: 'Akcia',
          },
        },
        doctors: {
          labels: {
            name: 'Kód/meno lekára',
            expertise: 'Kód/názov odbornosti',
            workingTime: 'Typ úväzku',
          },
          columns: {
            name: 'Kód/meno lekára',
            ambulance: 'Kód/názov ambulancie',
            expertise: 'Kód/názov odbornosti',
            workingTime: 'Typ úväzku',
            valid: 'Platný',
          },
        },
      },
      ambulanceDetail: {
        emptyState: 'Nenašli sme žiadne výsledky',
        card: {
          titleAddress: 'Adresa prevádzky',
          titleOfficeHours: 'Ordinačné hodiny',
          btnChangeOfficeHours: 'Zmeniť ordinačné hodiny',
          btnSetAbsence: 'Nahlásiť neprítomnosť',
          tootlip:
            'Zmenu ordinačných hodín nám nahláste cez Schránku správ alebo<br />na poskytovatel@dovera.sk.<br /><b>PR</b> = Prestávka<br /><b>LO</b> = Len na objednávku<br /><b>SV</b> = Špeciálne vyšetrenia a odbery<br /><b>AZ</b> = Ambulancia zatvorená - návštevy, administratíva<br /><b>OD</b> = Objednávky pacientov Dôvery',
          tooltipAbsence:
            'Vyplňte formulár a pošlite nám ho<br />cez Schránku správ alebo na<br />poskytovatel@dovera.sk.',
        },
        table: {
          title: 'Zoznam lekárov v ambulancii',
          labels: {
            name: 'Kód/meno lekára',
            expertise: 'Kód/názov odbornosti',
            workingTime: 'Typ úväzku',
            validity: 'Platný',
          },
        },
      },
    },
    loginDetails: {
      warning:
        'Nové prihlasovacie údaje si nezabudnite aktualizovať aj vo svojom ambulantnom softvéri.',
      subtitleLogin: 'Prihlasovacie meno',
      subtitlePassword: 'Prihlasovacie heslo',
      labels: {
        currentLogin: 'Aktuálne prihlasovacie meno',
        newLogin: 'Nové prihlasovacie meno',
        currentPassword: 'Aktuálne prihlasovacie heslo',
        newPassword: 'Nové prihlasovacie heslo',
        repeatNewPassword: 'Zopakujte prihlasovacie heslo',
        button: 'Potvrdiť zmenu',
      },
      tooltips: {
        newPassword:
          'Heslo musí obsahovať:<ul class="no-mrg"><li>min. 6, max. 128 znakov,</li><li>veľké aj malé písmená,</li><li>aspoň jednu číslicu.</li></ul>Dlhšie heslá sú bezpečnejšie.',
      },
    },
  },
  validation: {
    required: {
      default: 'Povnné pole',
    },
    incorrectCharacter: 'Nepovolený znak',
  },
  passwordIndicator: {
    weekLabel: 'Slabé heslo',
    strongLabel: 'Silné heslo',
  },
  capsLockWarning: 'Máte zapnutý Caps Lock - povoľuje iba veľké písmená.',
  form: {
    successDefault: 'Vaše údaje boli úspešne zmenené.',
    successFormDefault:
      'Údaje boli úspešne zmenené. Do piatich minút sa nimi môžete prihlásiť.',
  },
  feedbacker: {
    standard: {
      feedbackButton: 'Váš názor',
      firstStep: {
        label: 'Nakoľko Vám pomohla táto stránka?',
        rating: 'Hodnotenie',
      },
      secondStep: {
        badRating: {
          label: 'Na aký problém ste narazili?',
          checkboxes: {
            narocnaOrientacia: 'Náročná orientácia na stránke',
            parametre: 'Nesprávne vyhodnotené parametre',
            zlozitost: 'Zložitý text',
            ine: 'Iné',
          },
        },
        goodRating: {
          label: 'Čo sa vám páčilo?',
          checkboxes: [
            'Prehľadná stránka',
            'Prínosné informácie',
            'Jednoduché elektronické služby',
            'Iné',
          ],
        },
        submit: 'Odoslať',
      },
      lastStep: {
        success: 'Ďakujeme za spätnú väzbu!',
        error: 'Prepáčte, nepodarilo sa odoslať spätnú väzbu. (Skúsiť znovu)',
      },
    },
    trigger: {
      feedbackButton: 'Váš názor',
      firstStep: {
        label: 'Aká bola vaša skúsenosť s používaním aplikácie?',
        rating: 'Hodnotenie',
        closeBtn: 'V budúcnosti sa ma na názor nepýtajte',
      },
      secondStep: {
        badRating: {
          label: 'Ktoré funkcionality sa vám nepáčili?',
        },
        goodRating: {
          label: 'Ktoré funkcionality sa vám páčili?',
        },
        textarea: 'Napíšte nám návrh na zlepšenie',
        submit: 'Odoslať',
      },
      lastStep: {
        success: 'Ďakujeme za spätnú väzbu!',
        error: 'Prepáčte, nepodarilo sa odoslať spätnú väzbu. (Skúsiť znovu)',
      },
    },
    errors: {
      validation: 'Vyberte aspoň jednu z možností.',
      validationText: 'Napíšte Váš názor',
    },
  },
  poistenci: {
    kapitovani: {
      title: 'Poistenci',
      subtitle: 'Prehľad kapitovaných a sporných poistencov',
      baseFilter: [
        {
          text: 'Kapitovaní',
          value: routes.kapitovaniPoistenci,
        },
        {
          text: 'Sporní',
          value: routes.sporniPoistenci,
        },
      ],
      filterDohoda: [
        {
          text: 'Všetky',
          value: 'all',
        },
        {
          text: 'Platná',
          value: 'P',
        },
        {
          text: 'Neplatná',
          value: 'N',
        },
      ],
      labels: {
        poi: 'Poistenec',
        kod: 'Kód/meno lekára',
        export: 'Excel',
        exportCSV: 'CSV',
        konflikt: 'Konflikt s lekármi',
        vznikKonfliktu: 'Vznik konfliktu',
        vyradit: 'Vyradiť',
        ponechat: 'Ponechať',
        dohoda: 'Dohoda o poskytovaní ZS',
        predchadzajuciLekar: 'Predchádzajúci lekár',
        kapitovanyOd: 'Kapitovaný od',
        kapitovanyDo: 'Kapitovaný do',
        platnaDohoda: 'Platná dohoda',
      },
      columnsCap: [
        'Poistenec',
        'Kód/meno lekára',
        'Predchádzajúci lekár',
        'Kapitovaný od',
        'Kapitovaný do',
        'Platná dohoda',
      ],
      columnsConflict: [
        'Poistenec',
        'Kód/meno lekára',
        'Konflikt s lekármi',
        'Vznik konfliktu',
        '',
      ],
      tips: {
        title: 'Ako vyriešiť kapitačný konflikt?',
        description: '',
      },
      tooltips: {
        keepLongTime:
          'Poistenec je sporný už dlhší čas.<br />Ak si ho chcete ponechať, nahrajte dohodu.',
      },
      modals: {
        labels: {
          poi: 'Poistenec:',
          doctors: 'Lekári v konflikte:',
        },
        discardPatient: {
          title: 'Vyradiť pacienta a ukončiť dohodu?',
          labels: {
            datepicker: 'Ukončiť dohodu k dátumu',
            button: 'Ukončiť dohodu',
          },
          description:
            'Na tohto poistenca si poslal prírastkovú dávku iný lekár, ale neevidujeme úbytkovú dávku od Vás. Alebo ste si prírastkovú dávku poslali omylom.',
          toast:
            'Konflikt bol vyriešený. Do poisťovne už nemusíte posielať úbytkovú dávku.',
        },
        keepPatient: {
          title: 'Priložte, prosím, aktuálnu dohodu',
          labels: {
            attachment: 'Priložiť dohodu',
            attachmentDescription: 'Presuňte dohodu sem alebo kliknite na',
            button: 'Odoslať',
          },
          description:
            'Ak si chcete tohto poistenca ponechať ako kapitovaného, pošlite nám aktuálnu Dohodu o poskytovaní zdravotnej starostlivosti. Overíme jej platnosť a odpovieme Vám čo najskôr.',
          error: 'Pre odoslanie je potrebné priložiť prílohu.',
          toast:
            'O vyriešení konfliktu Vás budeme informovať mailom/cez schránku správ.',
        },
      },
    },
  },
  auth: {
    banner: {
      title: 'Upravte si účet podľa seba',
      description:
        '<ul><li class="mb-small">Zdieľate tento účet s inými kolegami? Môžete si vytvoriť svoj vlastný.</li><li class="mb-small">Dostanete údaje o pacientoch a diagnózach prepočítané na konkrétneho lekára.</li><li>Ak tento účet používate iba vy, skontrolujte a aktualizujte si Vaše osobné údaje.</li></ul>',
      description2:
        '<ul><li class="mb-small icon-security">Pre zvýšenie bezpečnosti a zobrazovanie dát na mieru potrebujeme, aby mal každý svoj vlastný účet s grid kartu.</li><li class="mb-small icon-user">Ak používate grid kartu svojho kolegu, založíme Vám vlastný účet.</li><li class="mb-small icon-edit">Ak tento účet používate iba Vy, stačí keď doplníte Vašu pozíciu a aktualizujete osobné údaje.</li><li class="icon-hearth">Upravením Vášho účtu získate navyše niekoľko výhod, a aplikáciu personalizujeme iba pre Vás.</li></ul>',
      description2Hidden:
        '<ul><li class="mb-small icon-sign">Ak ste štatutár, dostanete relevantné informácie k zmluvnej dokumentácii a môžete elektronicky podpisovať zmluvné dokumenty.</li><li class="mb-small icon-statistics">Každý lekár na svojom účte získa údaje o pacientoch, ktorých ošetril a môže sa porovnať s inými lekármi vo svojej odbornosti.</li><li class="icon-key">Mnoho ďalších výhod ako je vyššia bezpečnosť údajov o pacientoch, prihlásenie emailom, na ktorý budete dostávať informácie, dôležité pre Vašu pozíciu.</li><li>Pre účtovníkov a iné administratívne pozície garantujeme naďalej prístup k potrebným údajom, avšak iba relevantným pre ich pozíciu.</li></ul>',
      buttonShowBenefits: 'Zobraziť výhody',
      buttonHideBenefits: 'Skryť výhody',
      button: 'Pokračovať',
    },
    modal: {
      title: 'Vytvorte si vlastný účet<br />v DôveraLekárom',
      description:
        '<ul><li class="mb-xsmall">Uvidíte iba svojich pacientov a údaje za svoju ambulanciu.</li><li class="mb-xsmall">Ak tento prístup používate viacerí, vytvoríte si vlastné prihlasovacie údaje.</li><li>Dostanete pravidelne aktualizované dáta o diagnózach pacientov.</li></ul>',
      button: 'Pokračovať',
      buttonShowBenefits: 'Zobraziť výhody',
      buttonHideBenefits: 'Skryť výhody',
    },
    bannerPatients: {
      title: 'Chcete vidieť iba svojich pacientov?',
      description:
        'Vytvorte si svoj vlastný účet alebo si personalizujte existujúci.',
      button: 'Vytvoriť účet',
    },
    bannerCard: {
      title: 'Vytvorte si vlastný účet v&nbsp;3&nbsp;krokoch',
      description:
        'Dostanete aktuálne dáta iba pre Vás alebo si personalizujte existujúci.',
      button: 'Vytvoriť účet',
    },
    page: {
      step1: {
        title: 'Prihlasujete sa jednou GRID kartou viacerí?',
        stepTitle: 'Ako sa prihlasujete',
        y: 'Áno',
        n: 'Nie',
        notification:
          'Ak sa GRID kartou prihlasujete viacerí, v ďalších krokoch si vytvoríte vlastný účet.',
      },
      step2: {
        title: 'Aká je Vaša pozícia?',
        stepTitle: 'Výber pozície',
        description: 'Vyberte maximálne dve pozície.',
        infoFuture:
          'V budúcnosti budú dostupné aj pozície ako zdravotná sestra, administratívna pozícia a iné.',
        L: {
          description: 'Máte kód lekára',
        },
        S: {
          description: 'Podpisujete zmluvné dokumenty',
        },
        Z: {
          description: 'Staráte sa o pacientov',
        },
        A: {
          description: 'Napr. manažér, účtovník, IT správca,...',
        },
        I: {
          description: 'Napíšte svoju pozíciu',
        },
        errors: {
          default: 'Vyberte aspoň jednu pozíciu.',
          differentPosition: 'Musíte vyplniť svoju pozíciu.',
        },
        warnings: {
          doctorSister:
            'Kombinácia lekára a sestry nie je možná. Zvoľte, prosím, inú pozíciu.',
          max2Positions:
            'Kombinácia 3 a viac pozícií nie je možná. Zvoľte, prosím, maximálne 2 pozície.',
        },
      },
      step3: {
        title: 'Osobné údaje',
        stepTitle: 'Osobné údaje',
        labels: {
          doctorCode: 'Kód lekára',
          titleBefore: 'Titul pred menom',
          titleAfter: 'Titul za menom',
          firstName: 'Meno',
          lastName: 'Priezvisko',
          email: 'E-mail',
          repeatEmail: 'Zopakujte e-mail',
          phone: 'Pevná linka',
          mobile: 'Mobil',
          chooseStatutory: 'Vyberte štatutára',
          statutoryRole: 'Funkcia štatutára',
        },
        helpers: {
          doctorCode: 'Zadajte kód v tvare Axxx45 (bez kódu odbornosti).',
        },
        buttons: {
          home: 'Domov',
          back: 'Späť',
          continue: 'Pokračovať',
          submit: 'Uložiť údaje',
        },
        dontSeeExpertises: 'Nevidíte všetky Vaše odbornosti?',
        dontSeeExpertisesModal: {
          title:
            'Požiadajte vlastníka elektronickej pobočky o nahlásenie úväzku',
          description: (email: string) =>
            `E-mail na vlastníka Vašej elektronickej pobočky je <b>${email}</b>.`,
          close: 'Zavrieť',
        },
        tootlips: {
          expertises:
            'Odbornosti zobrazujeme podľa<br />úväzkov u daného poskytovateľa.',
          rfoTitles:
            'Tituly zobrazujeme tak, ako sú<br />evidované v Registri fyzických osôb.',
        },
      },
      stepResult: {
        success: {
          title: 'Ďakujeme Vám za doplnenie údajov',
          titleApproval: 'Prístup bol schválený',
          description: 'Môžete pokračovať do služby DôveraLekárom.',
          descriptionApproval: (name: string) =>
            `<b>${name}</b> teraz môže používať svoj vlastný účet v DôveraLekárom.<br />Na mail sme mu poslali vlastnú GRID kartu na prihlásenie.`,
        },
        warning: {
          title: 'Účet musí schváliť vlastník prístupu',
          titleDisapproval: 'Prístup nebol schválený',
          description: (email: string) =>
            `Na <b>${email}</b> sme poslali žiadosť o schválenie účtu.<br />Pokiaľ Vám nepríde e-mail o schválení, prihlasujte sa tak, ako doteraz.`,
          descriptionDisapproval: (name: string) =>
            `<b>${name}</b> nebude používať vlastný účet v DôveraLekárom. Bude sa prihlasovať ako doteraz.`,
        },
        warningHK: {
          title: 'Prijali sme Vašu žiadosť o vytvorenie nového účtu',
          description:
            'Kým Vám nepríde e-mail s novou GRID kartou, prihlasujte sa tak, ako doteraz.',
        },
        error: {
          title: 'Prepáčte, účet sa nepodarilo vytvoriť',
          titleApproval:
            'Prepáčte, nastala technická chyba pri schvaľovaní prístupu',
          description:
            'Skúste znova načítať stránku.<br />Ak chyba pretrváva, napíšte nám na <a href="mailto:poskytovatel@dovera.sk">poskytovatel@dovera.sk</a>.',
        },
        errorPersonalized: {
          title: 'Prepáčte, účet sa nepodarilo personalizovať',
          titleApproval:
            'Prepáčte, nastala technická chyba pri schvaľovaní prístupu',
          description:
            'Skúste to ešte raz a ak chyba pretrváva,<br />napíšte nám na napíšte nám na <a href="mailto:poskytovatel@dovera.sk">poskytovatel@dovera.sk</a>.',
        },
        buttons: {
          close: 'Zavrieť',
          home: 'Domov',
          tryAgain: 'Skúsiť znova',
        },
      },
      step4: {
        title: 'Vytvorte si prihlasovacie údaje',
        stepTitle: 'Prihlasovacie údaje',
        labels: {
          login: 'Prihlasovacie meno',
          loginPass: 'Prihlasovacie heslo',
          repeatPassword: 'Zopakujte prihlasovacie heslo',
        },
        warning:
          '<b>Heslo si dobre poznačte</b>, budete ho potrebovať na stiahnutie GRID karty.',
        buttons: {
          back: 'Späť',
          submit: 'Vytvoriť účet',
        },
      },
      approvalAccess: {
        labels: {
          status: 'Stav prístupu:',
          type: 'Typ prístupu:',
          doctorCode: 'Kód lekára:',
          email: 'E-mail',
          phone: 'Pevná linka',
          mobile: 'Mobil',
        },
        subtitles: {
          position: 'Pozícia',
          sectionsEP: 'Sekcie v elektronickej pobočke',
          sectionsDLek: 'Sekcie v DôveraLekárom',
        },
        buttons: {
          approve: 'Schváliť prístup',
          disapprove: 'Neschváliť',
          cancel: 'Zrušiť',
        },
        modals: {
          accessApprove: {
            buttons: {
              primary: 'Schváliť prístup',
              secondary: 'Upraviť',
              destructive: 'Neschváliť',
            },
            labels: {
              role: 'Rola:',
              accessType: 'Typ prístupu:',
              email: 'E-mail:',
              phone: 'Pevná linka:',
              mobile: 'Mobil:',
            },
            subtitles: {
              ep: 'Elektronická pobočka',
              dLek: 'Dôvera Lekárom',
            },
          },
          accessDisapprove: {
            label: 'Dôvod neschválenia',
            help: 'Prepošleme ho žiadateľovi o prístup.',
            error: 'Zadajte dôvod neschválenia.',
            title: 'Prístup nebude schválený',
          },
        },
        notifications: {
          info: 'Pre sekciu Bezpečné lieky a sekciu Návrhy na ZS sa Vám na nový účet prenesú rovnaké nastavenia ako ste mali na tomto účte. Upraviť nastavenia si môžete následne v Správe prístupov vo Vašej elektronickej pobočke. K tomu je potrebná sekcia Správa prístupov.',
        },
      },
    },
  },
  // Kupelne navrhy
  proposals: {
    modals: {
      cancelProposal: {
        title: 'Chcete zrušiť kúpeľný návrh?',
        description:
          'Rozpracovaný návrh <b>nebude uložený</b> a budete ho musieť vyplniť odznova.',
        buttons: {
          continue: 'Pokračovať vo vypĺňaní',
          cancel: 'Zrušiť návrh',
        },
      },
      reservationCode: {
        title: 'Zadajte rezervačný kód',
        label: 'Rezervačný kód',
        error: 'Tento kód nie je platný.',
        button: 'Pridať návrh',
      },
      reservationDate: {
        title: 'Zadajte dátumy rezervácie',
        titleUpdate: 'Zadajte nové dátumy rezervácie',
        titleDelete: 'Chcete zrušiť rezerváciu?',
        labels: {
          end: 'Koniec',
          start: 'Začiatok',
        },
        button: 'Potvrdiť',
        buttonUpdate: 'Upraviť',
        buttonDelete: 'Zrušiť',
      },
      toasts: {
        reservationDate: 'Dátum rezervácie bol pridaný.',
        reservationDateUpdated: 'Dátum bol upravený.',
        reservationDateDeleted: 'Rezervácia bola zrušená.',
      },
      notValid: {
        title: 'Návrh nespĺňa podmienky.<br />Chcete ho aj tak odoslať?',
        description:
          'Pravdepodobne ho nebudeme môcť schváliť, pretože<br />nie sú splnené tieto podmienky:',
        buttons: {
          submit: 'Odoslať návrh',
          cancel: 'Zrušiť návrh',
          edit: 'Upraviť návrh',
        },
        conditions: {
          debtor:
            'Pacient je dlžník. Na kúpele bude mať nárok <b>až po zaplatení dlhu</b>.',
          recommendations70:
            'Pacient nad 75 rokov potrebuje <b>odporúčania od internistu/kardiológa</b>.',
          contraindications: 'Pacient <b>má niektorú z kontraindikácií</b>.',
          noExaminations: 'Pacient <b>nemá potrebné vyšetrenia</b>.',
          svlz: '<b>Klinické a SVLZ vyšetrenia</b> musia mať menej ako 6 mesiacov.',
          txsControls: {
            CerpanieKNsJednoraz:
              'Indikačná skupina povoľuje <b>jednorázové čerpanie kúpeľnej liečby.</b> Pacient ju už absolvoval.',
            CerpanieKNsPeriod: (term: number) =>
              `S danou indikačnou skupinou má pacient nárok na kúpele <b>${
                term === 24 ? 'raz za 2 roky' : 'raz za rok'
              }</b>.`,
            ExistSchvaleneRozhodnutie: 'Evidujeme schválené rozhodnutie.',
            NedostPocetNavstChronik:
              '<b>Nebola dodržaná liečba</b> chronického pacienta.',
            NedostPocetNavstSusLiecbu:
              'Neevidujeme pravidelnú liečbu diagnózy.',
            NenajdenaHospitalizacia: 'Nebola nájdená hospitalizácia pacienta.',
            NenajdenaOperacia: 'Nebola nájdená operácia pacienta.',
            EsteJePriskoro: 'Návrh podávate priskoro.',
            UzJeNeskoro: 'Návrh podávate neskoro.',
            IsVekPoi: 'Pacient nespĺňa vekové kritérium indikačnej skupiny.',
            Dlznik:
              'Pacient je dlžník. Na kúpele bude mať nárok <b>až po zaplatení dlhu</b>.',
          },
        },
      },
    },
    new: {
      title: 'Nový návrh',
      breadcrumbs: {
        proposals: 'Návrhy',
        listOfProposals: 'Zoznam podaných návrhov',
      },
      stepTitles: {
        step1: 'Navrhujúci lekár',
        step2: 'Informácie o pacientovi',
        step3: 'Diagnóza a indikačná skupina',
        step4: 'Objektívny nález a vedľajšie diagnózy',
        step5: 'Doplňujúce informácie',
      },
      modals: {
        cancelProposal: {
          title: 'Chcete zrušiť kúpeľný návrh?',
          description:
            'Rozpracovaný návrh <b>nebude uložený</b> a budete ho musieť vyplniť odznova.',
        },
        proposalWithErrors: {
          title: 'Návrh nespĺňa podmienky.<br />Chcete ho aj tak odoslať?',
          description:
            'Pravdepodobne ho nebudeme môcť schváliť, pretože<br />nie sú splnené tieto podmienky:',
        },
      },
      buttons: {
        continue: 'Pokračovať',
        cancel: 'Zrušiť návrh',
        showIndicatorsList: 'Pozrieť indikačný zoznam',
        submit: 'Odoslať návrh',
        savePDF: 'Uložiť ako PDF',
        continueFilling: 'Pokračovať vo vypĺňaní',
        edit: 'Upraviť návrh',
        newProposal: 'Vytvoriť nový návrh',
        home: 'Domov',
        tryAgain: 'Vyplniť znovu',
      },
      labels: {
        chooseDoctor: 'Vyberte lekára',
        chooseAmbulance: 'Vyberte ambulanciu',
        doctor: 'Lekár',
        ambulance: 'Ambulancia',
        email: 'E-mail',
        patientInsureeId: 'Rodné číslo pacienta',
        insureeId: 'Rodné číslo',
        patient: 'Pacient',
        name: 'Meno a priezvisko',
        address: 'Adresa',
        mobile: 'Mobil',
        indicatorGroup: 'Zadajte indikačnú skupinu',
        diagnose: 'Zadajte diagnózu',
        diagnoseDate: (type: 'L' | 'D' | 'H' | 'O'): string => {
          if (type === 'L') return 'Kedy bola ukončená liečba?';
          if (type === 'D') return 'Kedy bolo diagnostikované ochorenie?';
          return type === 'H'
            ? 'Kedy bola ukončená hospitalizácia?'
            : 'Kedy bol pacient na operácii?';
        },
        contraindications: 'Má pacient niektorú z kontraindikácií?',
        examinations: 'Má pacient tieto vyšetrenia?',
        SVLZ: 'Majú klinické a SVLZ vyšetrenia menej ako 6 mesiacov?',
        reason: 'Popíšte dôvod kúpeľnej starostlivosti a epikrízu',
        medicalHistory: `Popíšte anamnézu, užívané lieky, patologický a objektívny nález so zhodnotením funkčného stavu`,
        secondaryDiagnoses: 'Vyberte vedľajšie diagnózy pacienta',
        bathType: 'Aký typ kúpeľnej staroslivosti si pacient praje?',
        bathTypeSingle: 'Kúpeľná starostlivosť',
        escort: 'Bude mať pacient sprievod?',
        helpingEscort: 'Vyberte, s čím bude sprievod pomáhať',
        mobility: 'Aká je pohyblivosť pacienta?',
        proposalConfirmation:
          'Má pacient <b>potvrdenie od ošetrujúceho lekára, že zvládne kúpeľnú liečbu</b> (balneologickú záťaž)?',
      },
      summary: {
        indicatorGroup: 'Indikačná skupina',
        diagnose: 'Diagnóza',
        doctorConfirmation: 'Potvrdenie od ošetrujúceho lekára',
        patientOperation: 'Pacient bol operovaný',
        patientHosp: 'Pacient bol hospitalizovaný',
        contraindications: 'Kontraindikácie',
        examinations: 'Vyšetrenia',
        svlz: 'Klinické a SVLZ vyšetrenia nie staršie ako 6 mesiacov',
        epicrisis: 'Epikríza',
        medicalHistory: 'Anamnéza, patologický a objektívny nález',
        anotherDiagnoses: 'Vedľajšie diagnózy',
      },
      options: {
        yes: 'Áno',
        no: 'Nie',
        has: 'Má',
        hasnt: 'Nemá',
        spaType: (single?: boolean) => ({
          A: single ? 'Ambulantná' : 'Ambulantnú',
          U: single ? 'Ústavná' : 'Ústavnú',
        }),
        mobilityType: {
          C: 'Chodí',
          B: 'Chodí pomocou barlí',
          V: 'Je na vozíku',
        },
        walks: 'Chodí',
        crutches: 'Chodí pomocou barlí',
        weelchair: 'Je na vozíku',
        catering: 'Stravovanie',
        dressing: 'Obliekanie',
        hygiene: 'Osobná hygiena',
        wc: 'Presun na WC',
      },
      helpers: {
        insureeId: 'Zadajte bez lomky',
        contraindications:
          '<li>Infekčné choroby prenosné na človeka a bacilonosičstvo.<br />Ak je indikovaná choroba združená s tbc dýchacieho ústrojenstva alebo inou formou tbc, kúpeľná starostlivosť sa povoľuje, iba ak bol pacient vyradený z dispenzárnych skupín aktívnej tuberkulózy.</li><li>Všetky choroby v akútnom štádiu)</li>',
        secondaryDiagnoses: 'Povolených je maximálne 5 diagnóz.',
      },
      inputErrors: {
        diagnose: 'Začnite písať kód alebo názov diagnózy.',
        epicrisis: 'Odôvodnite, prosím, kúpeľnú starostlivosť.',
        epicrisisLength: 'Epikríza musí obsahovať aspoň 160 znakov.',
        indicatorGroup: 'Začnite písať kód alebo názov indikácie.',
        reason: 'Odôvodnite, prosím, kúpeľnú starostlivosť.',
        anamnese: 'Prosím, zadajte objektívny nález.',
        anamneseLength: 'Objektívny nález musí obsahovať aspoň 300 znakov.',
        incorrectValue:
          'Prepíšte, prosím, text. Po prekopírovaní a vložení obsahuje nepovolený zdrojový kód.',
      },
      notifications: {
        errors: {
          debtor:
            'Poistenec je dlžník. Na kúpele bude mať nárok <b>po zaplatení dlhu.</b>',
          inactiveInsuree:
            'Nie je možné evidovať kúpeľný návrh pre neaktívneho poistenca.',
          missingConfirmations:
            'Pacient bude mať na kúpele <b>nárok po doplnení potvrdení.</b>',
          hasContraindications:
            'Ak má pacient niektorú z kontraindikácií, <b>návrh pravdepodobne nebude schválený.</b>',
          missingExaminations:
            'Pacient bude mať na kúpele <b>nárok po doplnení vyšetrení.</b>',
          missingYoungerExaminations:
            'Pacient bude mať na kúpele <b>nárok po doplnení vyšetrení</b>, ktoré nie sú staršie ako 6 mesiacov.',
          txsControls: {
            CerpanieKNsJednoraz:
              'Indikačná skupina povoľuje <b>jednorázové čerpanie kúpeľnej liečby.</b> Pacient ju už absolvoval.',
            CerpanieKNsPeriod: (term: number) =>
              strings.proposals.modals.notValid.conditions.txsControls.CerpanieKNsPeriod(
                term,
              ),
            ExistSchvaleneRozhodnutie: 'Evidujeme schválené rozhodnutie.',
            NedostPocetNavstChronik:
              'Nebola dodržaná <b>liečba chronického pacienta.</b> Návrh pravdepodobne nebude schválený.',
            NedostPocetNavstSusLiecbu:
              'Neevidujeme pravidelnú liečbu diagnózy. Návrh pravdepodobne nebude schválený.',
            NenajdenaHospitalizacia: 'Nebola nájdená hospitalizácia pacienta.',
            NenajdenaOperacia: 'Nebola nájdená operácia pacienta.',
            EsteJePriskoro: 'Návrh podávate priskoro.',
            UzJeNeskoro: 'Návrh podávate neskoro.',
            IsVekPoi:
              'Pacient nespĺňa vekové kritérium indikačnej skupiny. Vyberte, prosím, inú skupinu.',
            Dlznik:
              'Pacient je dlžník. Na kúpele bude mať nárok <b>až po zaplatení dlhu</b>.',
          },
        },
        info: {
          copyOfExaminations:
            'Pacientovi poskytnite kópie vyšetrení. Bude ich potrebovať pri nástupe do kúpeľov.',
        },
      },
      tooltips: {
        epicrisis: 'Stručný záver prepúšťacej správy a odporúčanie.',
        moreThan70: 'Platí pre pacientov od 75 rokov vrátane.',
      },
      mustKnowBeforeSubmit: [
        'Kúpele si po novom vyberá pacient. Po schválení návrhu mu pošleme zoznam odporúčaných kúpeľov.',
        'Za správnosť obsahu návrhu zodpovedáte Vy ako navrhujúci lekár.',
        'Pacientovi poskytnite, prosím, zdravotnú dokumentáciu, ktorú kúpele požadujú. Poisťovňa ju neposkytuje.',
      ],
      afterSubmit: {
        success: {
          title: 'Návrh sme prijali, výsledok Vám potvrdíme do 24 hodín',
          description: 'Výsledok schvaľovania pošleme Vám aj pacientovi.',
        },
        error: {
          title: 'Návrh pravdepodobne nebude schválený',
          description:
            'Prijali sme návrh, ktorý nespľňa podmienky schválenia.<br />Výsledok potvrdíme Vám aj pacientovi do 24 hodín.',
        },
        warning: {
          title: 'Prepáčte, návrh sa nepodarilo odoslať',
          description:
            'Skúste ho, prosím, vyplniť znovu.<br />Ak bude problém pretrvávať, <a href="mailto:poskytovatel@dovera.sk">napíšte nám</a>.',
        },
      },
      result: {
        success: {
          title: (proposalNumber?: string | null): string => {
            const propNumber = inlineStrCondition(
              !!proposalNumber,
              `${proposalNumber} `,
              '',
            );
            return `Návrh ${propNumber}sme prijali. O tom, či bol schválený,<br />Vás budeme informovať do 24 hodín.`;
          },
          description:
            'Výsledok schvaľovania pošleme mailom Vám aj pacientovi.',
          secondaryBtn: 'Vytvoriť nový návrh',
          btn: 'Domov',
        },
        warning: {
          title: (proposalNumber?: string | null) => {
            const propNumber = inlineStrCondition(
              !!proposalNumber,
              `${proposalNumber} `,
              '',
            );
            return `Návrh ${propNumber}pravdepodobne nebude schválený`;
          },
          description:
            'Prijali sme návrh, ktorý nespľňa podmienky schválenia.<br />Výsledok potvrdíme Vám aj pacientovi do 24 hodín.',
          secondaryBtn: 'Vytvoriť nový návrh',
          btn: 'Domov',
        },
        error: {
          title: (proposalNumber?: string | null) => {
            const propNumber = inlineStrCondition(
              !!proposalNumber,
              `${proposalNumber} `,
              '',
            );
            return `Prepáčte, návrh  ${propNumber}sa nepodarilo odoslať`;
          },
          description:
            'Skúste ho, prosím, vyplniť znovu.<br />Ak bude problém pretrvávať, <a href="mailto:poskytovatel@dovera.sk">napíšte nám</a>.',
          btn: 'Vyplniť znovu',
        },
      },
    },
    list: {
      emptyState: 'Nenašli sme žiadne návrhy',
      approvedProposals: {
        title: 'Zoznam schválených návrhov',
        infoReservation:
          'Zadaním rezervačného kódu si pridáte schválený návrh do tabuľky.',
        labels: {
          reservationCode: 'Zadať rezervačný kód',
          date: 'Zadať dátumy',
          detail: 'Detail',
          proposal: 'Návrh',
          insuree: 'Pacient',
          doctorCode: 'Kód/meno lekára',
          validity: 'Platnosť návrhu',
          reservation: 'Rezervácia kúpeľov',
        },
        filter: {
          insuree: 'Pacient/Rodné číslo',
          reservation: 'Rezervačný kód',
          validityFrom: 'Platnosť návrhu od',
          validityTo: 'Platnosť návrhu do',
          proposalNumber: 'Číslo návrhu',
          spaTreatment: 'Kúpeľná liečba',
          insureeVZ: 'Poistný vzťah pacienta',
          more: 'Viac filtrov',
          less: 'Menej filtrov',
        },
        tooltips: {
          inactive: 'Pacient už nie je poistenec Dôvery.',
        },
      },
      submittedProposals: {
        title: 'Zoznam podaných návrhov',
        labels: {
          newProposal: 'Podať nový návrh',
          proposalNumber: 'Číslo návrhu',
          insuree: 'Pacient/Rodné číslo',
          state: 'Stav',
          doctorCode: 'Kód/meno lekára',
          ambulance: 'Ambulancia',
          validityFrom: 'Platnosť návrhu od',
          validityTo: 'Platnosť návrhu do',
        },
        filter: {
          proposalNumber: 'Číslo návrhu',
          insuree: 'Pacient/Rodné číslo',
          state: 'Stav návrhu',
          more: 'Viac filtrov',
          less: 'Menej filtrov',
          doctorCode: 'Kód/meno lekára',
          ambulance: 'Ambulancia',
          validityFrom: 'Platnosť návrhu od',
          validityTo: 'Platnosť návrhu do',
        },
      },
    },
    detail: {
      buttons: {
        create: 'Zadať dátum rezervácie',
        edit: 'Upraviť',
        delete: 'Zrušiť',
        printPdf: 'Uložiť ako PDF',
      },
      title: (proposalNumber: string) => `Návrh č. ${proposalNumber}`,
      subtitles: {
        patient: 'Pacient',
        proposalDoctor: 'Navrhujúci lekár',
        information: 'Informácie o návrhu o zdravotnú starostlivosť',
        additionalInfo: 'Doplňujúce informácie',
        documents: 'Dokumenty',
      },
      labels: {
        name: 'Meno a priezvisko',
        insureeId: 'Rodné číslo',
        address: 'Adresa',
        mobile: 'Mobil',
        doctor: 'Lekár',
        hospital: 'Nemocnica',
        ambulance: 'Ambulancia',
        email: 'E-mail',
        diagnose: 'Diagnóza',
        indicatorGroup: 'Indikačná skupina',
        aproove: 'Potvrdenie od ošetrujúceho lekára',
        event: (str: EventType | string): string => {
          if (['Hospitalizacia', 'Operacia'].includes(str))
            return `Pacient bol ${inlineStrCondition(
              str === 'Hospitalizacia',
              'hospitalizovaný',
              'operovaný',
            )}`;
          if (str === 'Diagnostika') return 'Diagnostikované ochorenie';
          return str === 'UkoncenieLiecby' ? 'Ukončenie liečby' : '';
        },
        contraindications: 'Kontraindikácie',
        examinations: 'Vyšetrenia',
        svlz3Month: 'Klinické a SVLZ vyšetrenia nie staršie ako 6 mesiacov',
        epicrisis: 'Epikríza',
        anannese: 'Anamnéza, patologický nález, objektívny nález',
        additionalDiagnoses: 'Vedľajšie diagnózy',
        spaType: 'Kúpeľná starostlivosť',
        escort: 'Sprievod',
        escortType: 'Dôvod sprievodu',
        mobility: 'Pohyblivosť pacienta',
        exposed: (str: string) => `Vystavený ${str}`,
        accepted: 'Prijatý poisťovňou',
        approved: 'Schválený',
        resultDate: 'Dátum spracovania',
        notApproved: 'Neschválený',
        validity: 'Platnosť návrhu',
        insuranceStatemenet: 'Vyjadrenie poisťovne',
        treatment: 'Kúpeľná liečba',
        pin: 'PIN kód',
        reservation: 'Rezervácia kúpeľov',
      },
    },
  },
  fileUploadForm: {
    errors: {
      allowedFormats: (formats: ReactNode) => (
        <>
          <b>Súbor je v nesprávnom formáte.</b> Prosím, nahrajte ho ako{' '}
          {formats}.
        </>
      ),
      maxFileSize: (max: number) =>
        `<b>Súbor má viac ako ${max} MB</b>. Prosím, nahrajte ho v menšej veľkosti alebo ako .zip.`,
      maxUploadSize: (max: number, multiple?: boolean) =>
        multiple
          ? `Prílohy nesmú prekročiť <b>${max} MB</b>. Skúste ich poslať ako .zip.`
          : `Súbor presahuje limit <b>${max} MB</b>.`,
    },
  },
  schrankaSprav: {
    attachments: {
      error:
        'Počas stiahnutia prílohy nastala technická chyba. Skúste neskôr, prosím.',
    },
    buttons: {
      newMessage: 'Nová správa',
      cancel: 'Zrušiť správu',
      submit: 'Odoslať',
    },
    deleteMessage: (count: number) =>
      count === 1 ? 'Správa bola vymazaná' : 'Správy boli vymazané',
    restoreMessage: 'Správa bola úspešne obnovená',
    sendMessage: {
      error: 'Nepodarilo sa odoslať správu. Skúste to, prosím, znovu.',
      success: 'Správa bola úspešne odoslaná',
    },
    modals: {
      confirmRemoveModal: {
        description: (count: number) =>
          `Správ${
            count === 1 ? 'u' : 'y'
          } presunieme do priečinka <b>Vymazané</b>.`,
        title: (count: number) =>
          `Naozaj chcete zmazať ${
            count === 1 ? 'toto vlákno správ' : 'tieto vlákna správ'
          }?`,
        primaryBtn: 'Vymazať',
        secondaryBtn: 'Ponechať',
      },
      newMessageModal: {
        helpers: {
          email: 'Na e-mail pošleme len notifikácie k Vašej správe.',
        },
        labels: {
          email: 'Váš e-mail',
          theme: 'Téma',
          subtheme: 'Podtéma',
          message: 'Správa',
        },
        tooltips: {
          email:
            'Na uvedený e-mail Vám odošleme notifikáciu o spracovaní Vašej správy.',
        },
        themeLoadingError:
          'Nastala technická chyba pri načítavaní tém správy. Skúste, prosím, vytvoriť novú správu neskôr.',
      },
      cancelNewMessage: {
        title: 'Chcete naozaj odísť?',
        description: 'Správa sa po zrušení neuloží.',
        buttons: {
          continue: 'Pokračovať v správe',
          cancel: 'Odísť',
        },
      },
    },
    notifications: {
      archiv: 'Tu nájdete aj archívne správy.',
    },
    thread: {
      detail: {
        noReply:
          'Na tento automatický mail neodpovedajte, prosím. V prípade otázok sme tu pre Vás telefonicky na 0800 150 155 alebo mailom na <a href="mailto:poskytovatel@dovera.sk">poskytovatel@dovera.sk</a>.',
        tooLongThread:
          'Toto vlákno je už príliš dlhé. Ak máte otázky, napíšte novú správu.',
        buttons: {
          newMessage: 'Napísať novú správu',
          reply: 'Odpovedať',
          refresh: 'Obnoviť',
        },
      },
    },
    emptyStates: {
      loadError: {
        title: (single: boolean) =>
          `Nepodarilo sa načítať správ${single ? 'u' : 'y'}`,
        description: (single: boolean) =>
          `Prepáčte, nastala technická chyba pri otváraní správ${
            single ? 'y' : ''
          }.`,
        buttons: {
          home: 'Domov',
          loadAgain: 'Načítať znovu',
        },
      },
      noMessages: {
        title: 'Nemáte žiadne správy',
        buttons: {
          home: 'Domov',
          loadAgain: 'Nová správa',
        },
      },
    },
  },
};
