export const cmsPath = {
  zmluvy: {
    ASOC: {
      spolocne: {
        stavy: {
          empty: {
            vyhladavanie:
              '/zmluvy-a-dodatky/asoc/spolocne/stavy/empty/vyhladavanie',
            ziadneZmluvy:
              '/zmluvy-a-dodatky/asoc/spolocne/stavy/empty/ziadne-zmluvy',
          },
          error: {
            internet: '/zmluvy-a-dodatky/asoc/spolocne/stavy/error/internet',
          },
        },
        formulare: {
          warningPodmienky:
            '/zmluvy-a-dodatky/asoc/spolocne/formulare/warning-podmienky',
          errorPodmienky:
            '/zmluvy-a-dodatky/asoc/spolocne/formulare/error-podmienky',
        },
      },
      hromadnyPodpis: {
        formulare: {
          checkbox:
            '/zmluvy-a-dodatky/asoc/hromadny-podpis/formulare/checkbox-hromadny-podpis',
          podmienka:
            '/zmluvy-a-dodatky/asoc/hromadny-podpis/formulare/podmienka-podpisania',
        },
        stranka: '/zmluvy-a-dodatky/asoc/hromadny-podpis/popis',
      },
      vysledokPodpisania: {
        uspesnyPodpis:
          '/zmluvy-a-dodatky/asoc/vysledok-podpisania/uspesny-podpis',
        neuspesnyPodpis:
          '/zmluvy-a-dodatky/asoc/vysledok-podpisania/neuspesny-podpis',
        uspesnyHromadnyPodpis:
          '/zmluvy-a-dodatky/asoc/vysledok-podpisania/uspesny-hromadny-podpis',
      },
      zoznamZmluv: {
        tooltipy: {
          vyhladavanie:
            '/zmluvy-a-dodatky/asoc/zoznam-zmluv-a-dodatkov/tooltipy/vyhladavanie',
        },
      },
      individualnyPodpis: {
        formulare: {
          checkboxDodatok:
            '/zmluvy-a-dodatky/asoc/individualny-podpis/formulare/checkbox-individualny-podpis-dodatok',
          checkboxZmluva:
            '/zmluvy-a-dodatky/asoc/individualny-podpis/formulare/checkbox-individualny-podpis-zmluva',
        },
      },
    },
    PZS: {
      spolocne: {
        stavy: {
          empty: {
            ziadneZmluvy:
              '/zmluvy-a-dodatky/pzs/spolocne/stavy/empty/ziadne-zmluvy-v-stave',
          },
        },
      },
      individualnyPodpis: {
        tooltipy: {
          pripomienkovat:
            '/zmluvy-a-dodatky/pzs/individualny-podpis/tooltipy/pripomienkovat',
        },
        modalneOkna: {
          posunUcinnosti:
            '/zmluvy-a-dodatky/pzs/individualny-podpis/modalne-okna/posun-ucinnosti',
          pripomienkaZaevidovana:
            '/zmluvy-a-dodatky/pzs/individualny-podpis/modalne-okna/pripomienka-zaevidovana',
          pripomienkaNezaevidovana:
            '/zmluvy-a-dodatky/pzs/individualny-podpis/modalne-okna/pripomienka-nezaevidovana',
        },
        stavy: {
          error: {
            neopravnenyPodpis:
              '/zmluvy-a-dodatky/pzs/individualny-podpis/stavy/error/neopravneny-podpis',
            neopravnenyPopisKonatel:
              '/zmluvy-a-dodatky/pzs/individualny-podpis/stavy/error/neopravneny-podpis-chybajuci-konatel',
          },
          warning: {
            pozrietCelyDokument:
              '/zmluvy-a-dodatky/pzs/individualny-podpis/stavy/warning/pozriet-cely-dokument',
          },
        },
        formulare: {
          checkboxDodatok:
            '/zmluvy-a-dodatky/pzs/individualny-podpis/formulare/checkbox-dodatok',
          checkboxZmluva:
            '/zmluvy-a-dodatky/pzs/individualny-podpis/formulare/checkbox-zmluva',
          error: {
            obsahPripomienky:
              '/zmluvy-a-dodatky/pzs/individualny-podpis/formulare/error/obsah-pripomienky',
            predmetPripomienky:
              '/zmluvy-a-dodatky/pzs/individualny-podpis/formulare/error/predmet-pripomienky',
            vyjadrenieSuhlasu:
              '/zmluvy-a-dodatky/pzs/individualny-podpis/formulare/error/vyjadrenie-suhlasu',
          },
        },
      },
      vysledokPodpisania: {
        uspesnyPodpis:
          '/zmluvy-a-dodatky/pzs/vysledok-podpisania/uspesny-podpis-zmluvy',
        neuspesnyPodpis:
          '/zmluvy-a-dodatky/pzs/vysledok-podpisania/nepodarilo-sa-podpisat',
      },
      zmluvyDodatky: {
        modalneOkna: {
          nezobrazovatZmluvu:
            '/zmluvy-a-dodatky/pzs/zmluvy-a-dodatky/modalne-okna/nezobrazovat-zmluvu',
        },
        stavy: {
          empty: {
            ziadneZmluvyNaPodpis:
              '/zmluvy-a-dodatky/pzs/zmluvy-a-dodatky/stavy/empty/ziadne-zmluvy-na-podpis',
          },
        },
        tooltipy: {
          neplatnaZmluva:
            '/zmluvy-a-dodatky/pzs/zmluvy-a-dodatky/tooltipy/neplatna-zmluva',
          neplatnyDodatok:
            '/zmluvy-a-dodatky/pzs/zmluvy-a-dodatky/tooltipy/neplatny-dodatok',
          vyhladavanie:
            '/zmluvy-a-dodatky/pzs/zmluvy-a-dodatky/tooltipy/vyhladavanie',
        },
      },
      detailZmluvy: {
        stavy: {
          empty: {
            ziadneDodatky:
              '/zmluvy-a-dodatky/pzs/detail-zmluvy/stavy/empty/ziadne-dodatky',
          },
        },
      },
    },
  },
  PEK: {
    ASOC: {
      popis: {
        obsah: '/pek/asoc/pek-popis/pek-obsah',
        platnost: '/pek/asoc/pek-popis/pek-platnost',
        nadpis: '/pek/asoc/pek-popis/pek-nadpis',
      },
      sekcie: {
        i: '/pek/asoc/pek-sekcie/i-pouzivane-pojmy-alebo-co-tym-myslime',
        ii: '/pek/asoc/pek-sekcie/ii-bezpecnost-alebo-ako-chranime-vase-data',
        iii: '/pek/asoc/pek-sekcie/iii-bezpecnost-este-raz-alebo-ako-nam-pomozete-chranit-vase-data',
        iv: '/pek/asoc/pek-sekcie/iv-elektronicke-sluzby-alebo-co-vsetko-umoznuje-elektronicka-pobocka',
        v: '/pek/asoc/pek-sekcie/v-vzajomna-komunikacia-alebo-ako-funguje-uzatvaranie-zmluv-o-poskytovani-zdravotnej-starostlivosti-a-notifikacie',
        vi: '/pek/asoc/pek-sekcie/vi-nase-povinnosti-a-prava-alebo-co-musime-a-mozeme',
        vii: '/pek/asoc/pek-sekcie/vii-vase-prava-a-povinnosti-alebo-co-mozete-a-musite',
        viii: '/pek/asoc/pek-sekcie/viii-prevadzka-alebo-ako-je-technicky-zabezpecena-elektronicka-pobocka',
      },
    },
    PZS: {
      popis: {
        obsah: '/pek/pzs/pek-popis/pek-obsah',
        platnost: '/pek/pzs/pek-popis/pek-platnost',
        nadpis: '/pek/pzs/pek-popis/pek-nadpis',
      },
      sekcie: {
        i: '/pek/pzs/pek-sekcie/i-pouzivane-pojmy-alebo-co-tym-myslime',
        ii: '/pek/pzs/pek-sekcie/ii-bezpecnost-alebo-ako-chranime-vase-data',
        iii: '/pek/pzs/pek-sekcie/iii-flexibilita-alebo-ako-mozete-ziskat-a-upravovat-pristupy',
        iv: '/pek/pzs/pek-sekcie/iv-bezpecnost-este-raz-alebo-ako-nam-pomozete-chranit-vase-data',
        v: '/pek/pzs/pek-sekcie/v-elektronicke-sluzby-alebo-co-vsetko-umoznuje-elektronicka-pobocka',
        vi: '/pek/pzs/pek-sekcie/vi-bez-papierov-alebo-co-vsetko-nam-mozete-zasielat-iba-elektronicky-a-ako',
        vii: '/pek/pzs/pek-sekcie/vii-papiere-alebo-co-nie-je-mozne-elektronizovat',
        viii: '/pek/pzs/pek-sekcie/viii-vzajomna-komunikacia-alebo-ako-funguje-uzatvaranie-zmluv-o-poskytovani-zdravotnej-starostlivosti-a-notifikacie',
        ix: '/pek/pzs/pek-sekcie/ix-nase-povinnosti-a-prava-alebo-co-musime-a-mozeme',
        x: '/pek/pzs/pek-sekcie/x-vase-prava-a-povinnosti-alebo-co-mozete-a-musite',
        xi: '/pek/pzs/pek-sekcie/xi-prevadzka-alebo-ako-je-technicky-zabezpecena-elektronicka-pobocka',
        xii: '/pek/pzs/pek-sekcie/xii-zaverecne-a-prechodne-ustanovenia-alebo-co-vam-chceme-este-na-zaver-povedat',
      },
    },
  },
  HP: {
    prehlad: {
      texty: {
        disclaimerVyhodnotene:
          '/hodnotiace-parametre/prehlad-parametrov/texty/disclaimer-vyhodnotene-plnenie',
        disclaimerAktualne:
          '/hodnotiace-parametre/prehlad-parametrov/texty/disclaimer-aktualne-plnenie',
      },
      tooltipy: {
        spolocne: {
          hranicaFinLimituDP:
            '/hodnotiace-parametre/prehlad-parametrov/tooltipy/hranica-financneho-limitu-doverapomaha',
          odmenaAktualne:
            '/hodnotiace-parametre/prehlad-parametrov/tooltipy/odmena-aktualne-plnenie',
          aktualnePlnenie:
            '/hodnotiace-parametre/prehlad-parametrov/tooltipy/aktualne-plnenie',
          vyhodnotenePlnenie:
            '/hodnotiace-parametre/prehlad-parametrov/tooltipy/vyhodnotene-plnenie',
          buduceObdobie: {
            mimoObdobiaVolby:
              '/hodnotiace-parametre/prehlad-parametrov/tooltipy/buduce-obdobie/mimo-obdobia-volby',
            pocasObdobiaVolby:
              '/hodnotiace-parametre/prehlad-parametrov/tooltipy/buduce-obdobie/pocas-obdobia-volby',
            uhradaNavyse:
              '/hodnotiace-parametre/prehlad-parametrov/tooltipy/buduce-obdobie/uhrada-navyse',
          },
          DoplnKap:
            '/hodnotiace-parametre/prehlad-parametrov/tooltipy/dodatkova-kapitacia/doplnkova-kapitacia',
        },
        CenaBodu: {
          aktualneObdobie: {
            tooltip:
              '/hodnotiace-parametre/prehlad-parametrov/tooltipy/cena-bodu/cena-bodu-aktualne-plnenie',
            uhradaNavyse:
              '/hodnotiace-parametre/prehlad-parametrov/tooltipy/cena-bodu/uhrada-navyse-aktualne',
          },
          predchadzajuceObdobie: {
            tooltip:
              '/hodnotiace-parametre/prehlad-parametrov/tooltipy/cena-bodu/cena-bodu-vyhodnotene-plnenie',
            uhradaNavyse:
              '/hodnotiace-parametre/prehlad-parametrov/tooltipy/cena-bodu/uhrada-navyse-minule-plnenie',
          },
          vyhodnoteneObdobie: {
            uhradaNavyse:
              '/hodnotiace-parametre/prehlad-parametrov/tooltipy/cena-bodu/uhrada-navyse-vyhodnotene-plnenie',
          },
        },
        DodKap: {
          aktualneObdobie: {
            tooltip:
              '/hodnotiace-parametre/prehlad-parametrov/tooltipy/dodatkova-kapitacia/dodatkova-kapitacia-aktualne-plnenie',
            uhradaNavyse:
              '/hodnotiace-parametre/prehlad-parametrov/tooltipy/dodatkova-kapitacia/uhrada-navyse-aktualne',
          },
          predchadzajuceObdobie: {
            tooltip:
              '/hodnotiace-parametre/prehlad-parametrov/tooltipy/dodatkova-kapitacia/dodatkova-kapitacia-vyhodnotene-plnenie',
            uhradaNavyse:
              '/hodnotiace-parametre/prehlad-parametrov/tooltipy/dodatkova-kapitacia/uhrada-navyse-minule-plnenie',
          },
          vyhodnoteneObdobie: {
            uhradaNavyse:
              '/hodnotiace-parametre/prehlad-parametrov/tooltipy/dodatkova-kapitacia/uhrada-navyse-vyhodnotene-plnenie',
          },
        },
        Vykon8: {
          aktualneObdobie: {
            tooltip:
              '/hodnotiace-parametre/prehlad-parametrov/tooltipy/vykony/cena-vykonu-8-aktualne-plnenie',
          },
          predchadzajuceObdobie: {
            tooltip:
              '/hodnotiace-parametre/prehlad-parametrov/tooltipy/vykony/cena-vykonu-8-vyhodnotene-plnenie',
          },
        },
        Vykon4: {
          aktualneObdobie: {
            tooltip:
              '/hodnotiace-parametre/prehlad-parametrov/tooltipy/vykony/cena-vykonu-4-aktualne-plnenie',
          },
          predchadzajuceObdobie: {
            tooltip:
              '/hodnotiace-parametre/prehlad-parametrov/tooltipy/vykony/cena-vykonu-4-vyhodnotene-plnenie',
          },
        },
        Vykon1: {
          aktualneObdobie: {
            tooltip:
              '/hodnotiace-parametre/prehlad-parametrov/tooltipy/vykony/cena-vykonu-1-aktualne-plnenie',
          },
          predchadzajuceObdobie: {
            tooltip:
              '/hodnotiace-parametre/prehlad-parametrov/tooltipy/vykony/cena-vykonu-1-vyhodnotene-plnenie',
          },
        },
        Vykon250: {
          aktualneObdobie: {
            tooltip:
              '/hodnotiace-parametre/prehlad-parametrov/tooltipy/vykony/cena-vykonu-250-aktualne-plnenie',
          },
          predchadzajuceObdobie: {
            tooltip:
              '/hodnotiace-parametre/prehlad-parametrov/tooltipy/vykony/cena-vykonu-250-vyhodnotene-plnenie',
          },
        },
        hranicaFinLimituVLD:
          '/hodnotiace-parametre/prehlad-parametrov/tooltipy/vykony/hranica-fin-limitu-vld',
        hranicaFinLimituVLDD:
          '/hodnotiace-parametre/prehlad-parametrov/tooltipy/vykony/hranica-fin-limitu-vldd',
        uhradaNavyseAktualne:
          '/hodnotiace-parametre/prehlad-parametrov/tooltipy/vykony/vasa-uhrada-navyse-aktualne-plnenie',
        uhradaNavyseVyhodnotene:
          '/hodnotiace-parametre/prehlad-parametrov/tooltipy/vykony/vasa-uhrada-navyse-vyhodnotene-plnenie',
        uhradaNavyseMinule:
          '/hodnotiace-parametre/prehlad-parametrov/tooltipy/vykony/vasa-uhrada-navyse-minule-obdobie',
      },
    },
    spolocne: {
      texty: {
        vaha: '/hodnotiace-parametre/spolocne/texty/vaha-parametra',
        vld: '/hodnotiace-parametre/spolocne/texty/vld',
        vldd: '/hodnotiace-parametre/spolocne/texty/vldd',
      },
      tooltipy: {
        adresa: '/hodnotiace-parametre/spolocne/tooltipy/adresa',
        nedostupneVyhodnotenie:
          '/hodnotiace-parametre/spolocne/tooltipy/nedostupne-vyhodnotenie',
        priemernaHodnota:
          '/hodnotiace-parametre/spolocne/tooltipy/priemerna-hodnota',
        vasaPozicia: '/hodnotiace-parametre/spolocne/tooltipy/vasa-pozicia',
      },
    },
    Kvalita: {
      'subj-spok': {
        popis:
          '/hodnotiace-parametre/kvalita/subjektivna-spokojnost/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/kvalita/subjektivna-spokojnost/texty/staticky-obsah',
        vypocet: {
          citatel:
            '/hodnotiace-parametre/kvalita/subjektivna-spokojnost/vypocet/citatel',
          menovatel:
            '/hodnotiace-parametre/kvalita/subjektivna-spokojnost/vypocet/menovatel',
          vysledok:
            '/hodnotiace-parametre/kvalita/subjektivna-spokojnost/vypocet/vysledok',
        },
        tooltipy: {
          prehlad:
            '/hodnotiace-parametre/kvalita/subjektivna-spokojnost/tooltipy/prehlad',
        },
      },
      atb: {
        popis: '/hodnotiace-parametre/kvalita/predpis-atb/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/kvalita/predpis-atb/texty/staticky-obsah',
        vypocet: {
          hodnotaCitatel:
            '/hodnotiace-parametre/kvalita/predpis-atb/vypocet/citatel',
          AvgInd:
            '/hodnotiace-parametre/kvalita/predpis-atb/vypocet/index-rizika',
          PocPoi:
            '/hodnotiace-parametre/kvalita/predpis-atb/vypocet/pocet-pacientov',
          menovatel:
            '/hodnotiace-parametre/kvalita/predpis-atb/vypocet/menovatel',
          vysledok:
            '/hodnotiace-parametre/kvalita/predpis-atb/vypocet/vysledok',
        },
      },
      'atb-dp': {
        popis:
          '/hodnotiace-parametre/kvalita/predpis-atb-doverapomaha/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/kvalita/predpis-atb-doverapomaha/texty/staticky-obsah',
        vypocet: {
          hodnotaCitatel:
            '/hodnotiace-parametre/kvalita/predpis-atb-doverapomaha/vypocet/citatel',
          AvgInd:
            '/hodnotiace-parametre/kvalita/predpis-atb-doverapomaha/vypocet/index-rizika',
          PocPoi:
            '/hodnotiace-parametre/kvalita/predpis-atb-doverapomaha/vypocet/pocet-pacientov',
          vysledok:
            '/hodnotiace-parametre/kvalita/predpis-atb-doverapomaha/vypocet/vysledok',
        },
      },
      'bezp-med': {
        popis: '/hodnotiace-parametre/kvalita/bezpecna-medikacia/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/kvalita/bezpecna-medikacia/texty/staticky-obsah',
        vypocet: {
          citatel:
            '/hodnotiace-parametre/kvalita/bezpecna-medikacia/vypocet/citatel',
          menovatel:
            '/hodnotiace-parametre/kvalita/bezpecna-medikacia/vypocet/menovatel',
          vysledok:
            '/hodnotiace-parametre/kvalita/bezpecna-medikacia/vypocet/vysledok',
        },
      },
      'sch-dp': {
        popis: '/hodnotiace-parametre/kvalita/schodza-doverapomaha/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/kvalita/schodza-doverapomaha/texty/staticky-obsah',
        vypocet: {
          citatel:
            '/hodnotiace-parametre/kvalita/schodza-doverapomaha/vypocet/citatel',
          menovatel:
            '/hodnotiace-parametre/kvalita/schodza-doverapomaha/vypocet/menovatel',
          vysledok:
            '/hodnotiace-parametre/kvalita/schodza-doverapomaha/vypocet/vysledok',
        },
      },
      'pristr-vyb': {
        popis:
          '/hodnotiace-parametre/kvalita/pristrojove-vybavenie/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/kvalita/pristrojove-vybavenie/texty/staticky-obsah',
        vypocet: {
          citatel:
            '/hodnotiace-parametre/kvalita/pristrojove-vybavenie/vypocet/pocet-poct',
          menovatel:
            '/hodnotiace-parametre/kvalita/pristrojove-vybavenie/vypocet/pocet-abi',
        },
      },
      'pristr-vyb-tkho': {
        popis:
          '/hodnotiace-parametre/kvalita/pristrojove-vybavenie-holter/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/kvalita/pristrojove-vybavenie-holter/texty/staticky-obsah',
        vypocet: {
          PristrPOCT:
            '/hodnotiace-parametre/kvalita/pristrojove-vybavenie-holter/vypocet/pocet-poct',
          PristrABI:
            '/hodnotiace-parametre/kvalita/pristrojove-vybavenie-holter/vypocet/pocet-abi',
          PristrTKHO:
            '/hodnotiace-parametre/kvalita/pristrojove-vybavenie-holter/vypocet/pocet-holter',
        },
      },
      'std-hba1c': {
        popis:
          '/hodnotiace-parametre/kvalita/standard-liecby-hba1c/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/kvalita/standard-liecby-hba1c/texty/staticky-obsah',
        vypocet: {
          citatel:
            '/hodnotiace-parametre/kvalita/standard-liecby-hba1c/vypocet/citatel',
          menovatel:
            '/hodnotiace-parametre/kvalita/standard-liecby-hba1c/vypocet/menovatel',
          vysledok:
            '/hodnotiace-parametre/kvalita/standard-liecby-hba1c/vypocet/vysledok',
        },
      },
      'prev-kp-gyn': {
        popis:
          '/hodnotiace-parametre/kvalita/prevencia-karcinomu-prsnika/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/kvalita/prevencia-karcinomu-prsnika/texty/staticky-obsah',
        vypocet: {
          vysledok:
            '/hodnotiace-parametre/kvalita/prevencia-karcinomu-prsnika/vypocet/vysledok',
          citatel:
            '/hodnotiace-parametre/kvalita/prevencia-karcinomu-prsnika/vypocet/pocet-usg-a-mmg',
          menovatel:
            '/hodnotiace-parametre/kvalita/prevencia-karcinomu-prsnika/vypocet/menovatel',
          VysUSG:
            '/hodnotiace-parametre/kvalita/prevencia-karcinomu-prsnika/vypocet/pocet-usg',
          VysMMG:
            '/hodnotiace-parametre/kvalita/prevencia-karcinomu-prsnika/vypocet/pocet-mmg',
        },
      },
      'dostup-gyn': {
        popis:
          '/hodnotiace-parametre/kvalita/dostupnost-zdravotnej-starostlivosti-gyn/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/kvalita/dostupnost-zdravotnej-starostlivosti-gyn/texty/staticky-obsah',
        vypocet: {
          citatel:
            '/hodnotiace-parametre/kvalita/dostupnost-zdravotnej-starostlivosti-gyn/vypocet/najvyssi-uvazok',
          OHA: '/hodnotiace-parametre/kvalita/dostupnost-zdravotnej-starostlivosti-gyn/vypocet/ordinacny-cas',
          OHS: '/hodnotiace-parametre/kvalita/dostupnost-zdravotnej-starostlivosti-gyn/vypocet/prestavky',
        },
      },
      'prev-gyn': {
        popis:
          '/hodnotiace-parametre/kvalita/preventivne-prehliadky-gyn/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/kvalita/preventivne-prehliadky-gyn/texty/staticky-obsah',
        vypocet: {
          vysledok:
            '/hodnotiace-parametre/kvalita/preventivne-prehliadky-gyn/vypocet/vysledok',
          citatel:
            '/hodnotiace-parametre/kvalita/preventivne-prehliadky-gyn/vypocet/citatel',
          menovatel:
            '/hodnotiace-parametre/kvalita/preventivne-prehliadky-gyn/vypocet/menovatel',
        },
      },
      'podiel-fix-hyp': {
        popis:
          '/hodnotiace-parametre/kvalita/podiel-fixnych-kombinacii-na-celkovom-mnozstve-predpisovanych-liekov-na-hypertenziu/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/kvalita/podiel-fixnych-kombinacii-na-celkovom-mnozstve-predpisovanych-liekov-na-hypertenziu/texty/staticky-obsah',
        vypocet: {
          vysledok:
            '/hodnotiace-parametre/kvalita/podiel-fixnych-kombinacii-na-celkovom-mnozstve-predpisovanych-liekov-na-hypertenziu/vypocet/vysledok',
          citatel:
            '/hodnotiace-parametre/kvalita/podiel-fixnych-kombinacii-na-celkovom-mnozstve-predpisovanych-liekov-na-hypertenziu/vypocet/citatel',
          menovatel:
            '/hodnotiace-parametre/kvalita/podiel-fixnych-kombinacii-na-celkovom-mnozstve-predpisovanych-liekov-na-hypertenziu/vypocet/menovatel',
        },
      },
      'vakc-hpv': {
        popis:
          '/hodnotiace-parametre/kvalita/podiel-pacientov-chlapcov-aj-dievcat-ktori-dovrsili-13-rokov-a-su-vakcinovany-proti-hpv/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/kvalita/podiel-pacientov-chlapcov-aj-dievcat-ktori-dovrsili-13-rokov-a-su-vakcinovany-proti-hpv/texty/staticky-obsah',
        vypocet: {
          vysledok:
            '/hodnotiace-parametre/kvalita/podiel-pacientov-chlapcov-aj-dievcat-ktori-dovrsili-13-rokov-a-su-vakcinovany-proti-hpv/vypocet/vysledok',
          citatel:
            '/hodnotiace-parametre/kvalita/podiel-pacientov-chlapcov-aj-dievcat-ktori-dovrsili-13-rokov-a-su-vakcinovany-proti-hpv/vypocet/citatel',
          menovatel:
            '/hodnotiace-parametre/kvalita/podiel-pacientov-chlapcov-aj-dievcat-ktori-dovrsili-13-rokov-a-su-vakcinovany-proti-hpv/vypocet/menovatel',
        },
      },
      'farm-sz': {
        popis:
          '/hodnotiace-parametre/kvalita/podiel-pacientov-so-sz-v-kmeni-s-predpisanou-kombinaciou-lieciv-acei-arb-arni-bb-mra/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/kvalita/podiel-pacientov-so-sz-v-kmeni-s-predpisanou-kombinaciou-lieciv-acei-arb-arni-bb-mra/texty/staticky-obsah',
        vypocet: {
          vysledok:
            '/hodnotiace-parametre/kvalita/podiel-pacientov-so-sz-v-kmeni-s-predpisanou-kombinaciou-lieciv-acei-arb-arni-bb-mra/vypocet/vysledok',
          citatel:
            '/hodnotiace-parametre/kvalita/podiel-pacientov-so-sz-v-kmeni-s-predpisanou-kombinaciou-lieciv-acei-arb-arni-bb-mra/vypocet/citatel',
          menovatel:
            '/hodnotiace-parametre/kvalita/podiel-pacientov-so-sz-v-kmeni-s-predpisanou-kombinaciou-lieciv-acei-arb-arni-bb-mra/vypocet/menovatel',
        },
      },
      'alerg-imt': {
        popis:
          '/hodnotiace-parametre/kvalita/podiel-pacientov-s-alergickou-rinitidou-ktori-mali-v-poslednom-roku-predpisanu-liecbu-pomocou-alergenovej-imunoterapie/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/kvalita/podiel-pacientov-s-alergickou-rinitidou-ktori-mali-v-poslednom-roku-predpisanu-liecbu-pomocou-alergenovej-imunoterapie/texty/staticky-obsah',
        vypocet: {
          vysledok:
            '/hodnotiace-parametre/kvalita/podiel-pacientov-s-alergickou-rinitidou-ktori-mali-v-poslednom-roku-predpisanu-liecbu-pomocou-alergenovej-imunoterapie/vypocet/vysledok',
          citatel:
            '/hodnotiace-parametre/kvalita/podiel-pacientov-s-alergickou-rinitidou-ktori-mali-v-poslednom-roku-predpisanu-liecbu-pomocou-alergenovej-imunoterapie/vypocet/citatel',
          menovatel:
            '/hodnotiace-parametre/kvalita/podiel-pacientov-s-alergickou-rinitidou-ktori-mali-v-poslednom-roku-predpisanu-liecbu-pomocou-alergenovej-imunoterapie/vypocet/menovatel',
        },
      },
      'vakc-rotav': {
        popis:
          '/hodnotiace-parametre/kvalita/vakcinacia-proti-rotavirusu-v-ambulancii-vldd/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/kvalita/vakcinacia-proti-rotavirusu-v-ambulancii-vldd/texty/staticky-obsah',
        vypocet: {
          vysledok:
            '/hodnotiace-parametre/kvalita/vakcinacia-proti-rotavirusu-v-ambulancii-vldd/vypocet/vysledok',
          citatel:
            '/hodnotiace-parametre/kvalita/vakcinacia-proti-rotavirusu-v-ambulancii-vldd/vypocet/citatel',
          menovatel:
            '/hodnotiace-parametre/kvalita/vakcinacia-proti-rotavirusu-v-ambulancii-vldd/vypocet/menovatel',
        },
      },
      'std-nefro': {
        popis:
          '/hodnotiace-parametre/kvalita/vysetrovanie-nefrologickych-parametrov/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/kvalita/vysetrovanie-nefrologickych-parametrov/texty/staticky-obsah',
        vypocet: {
          vysledok:
            '/hodnotiace-parametre/kvalita/vysetrovanie-nefrologickych-parametrov/vypocet/vysledok',
          citatel:
            '/hodnotiace-parametre/kvalita/vysetrovanie-nefrologickych-parametrov/vypocet/citatel',
          menovatel:
            '/hodnotiace-parametre/kvalita/vysetrovanie-nefrologickych-parametrov/vypocet/menovatel',
        },
      },
      'liecba-po-cmp': {
        popis:
          '/hodnotiace-parametre/kvalita/hypolipidemicka-liecba-u-pacientov-po-cmp-a-tia/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/kvalita/hypolipidemicka-liecba-u-pacientov-po-cmp-a-tia/texty/staticky-obsah',
        vypocet: {
          vysledok:
            '/hodnotiace-parametre/kvalita/hypolipidemicka-liecba-u-pacientov-po-cmp-a-tia/vypocet/vysledok',
          citatel:
            '/hodnotiace-parametre/kvalita/hypolipidemicka-liecba-u-pacientov-po-cmp-a-tia/vypocet/citatel',
          menovatel:
            '/hodnotiace-parametre/kvalita/hypolipidemicka-liecba-u-pacientov-po-cmp-a-tia/vypocet/menovatel',
        },
      },
      'vys-deti': {
        popis:
          '/hodnotiace-parametre/kvalita/vysetrovanie-detskych-pacientov/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/kvalita/vysetrovanie-detskych-pacientov/texty/staticky-obsah',
        vypocet: {
          vysledok:
            '/hodnotiace-parametre/kvalita/vysetrovanie-detskych-pacientov/vypocet/vysledok',
          citatel:
            '/hodnotiace-parametre/kvalita/vysetrovanie-detskych-pacientov/vypocet/citatel',
          menovatel:
            '/hodnotiace-parametre/kvalita/vysetrovanie-detskych-pacientov/vypocet/menovatel',
        },
      },
      'vys-novi': {
        popis:
          '/hodnotiace-parametre/kvalita/vysetrovanie-novych-pacientov/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/kvalita/vysetrovanie-novych-pacientov/texty/staticky-obsah',
        vypocet: {
          vysledok:
            '/hodnotiace-parametre/kvalita/vysetrovanie-novych-pacientov/vypocet/vysledok',
          citatel:
            '/hodnotiace-parametre/kvalita/vysetrovanie-novych-pacientov/vypocet/citatel',
          menovatel:
            '/hodnotiace-parametre/kvalita/vysetrovanie-novych-pacientov/vypocet/menovatel',
        },
      },
      'atb-orl': {
        popis:
          '/hodnotiace-parametre/kvalita/racionalna-preskripcia-atb-pri-vybranych-diagnozach/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/kvalita/racionalna-preskripcia-atb-pri-vybranych-diagnozach/texty/staticky-obsah',
        vypocet: {
          vysledok:
            '/hodnotiace-parametre/kvalita/racionalna-preskripcia-atb-pri-vybranych-diagnozach/vypocet/vysledok',
          citatel:
            '/hodnotiace-parametre/kvalita/racionalna-preskripcia-atb-pri-vybranych-diagnozach/vypocet/citatel',
          menovatel:
            '/hodnotiace-parametre/kvalita/racionalna-preskripcia-atb-pri-vybranych-diagnozach/vypocet/menovatel',
        },
      },
      polyp: {
        popis:
          '/hodnotiace-parametre/kvalita/endoskopicka-resekcia-polypov/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/kvalita/endoskopicka-resekcia-polypov/texty/staticky-obsah',
        vypocet: {
          vysledok:
            '/hodnotiace-parametre/kvalita/endoskopicka-resekcia-polypov/vypocet/vysledok',
          citatel:
            '/hodnotiace-parametre/kvalita/endoskopicka-resekcia-polypov/vypocet/citatel',
          menovatel:
            '/hodnotiace-parametre/kvalita/endoskopicka-resekcia-polypov/vypocet/menovatel',
        },
      },
    },
    Efektívnosť: {
      'prev-vas': {
        popis:
          '/hodnotiace-parametre/efektivnost/preventivne-prehliadky/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/efektivnost/preventivne-prehliadky/texty/staticky-obsah',
        'vldd-extra':
          '/hodnotiace-parametre/efektivnost/preventivne-prehliadky/texty/extra-vldd',
        vypocet: {
          citatel:
            '/hodnotiace-parametre/efektivnost/preventivne-prehliadky/vypocet/citatel',
          menovatel:
            '/hodnotiace-parametre/efektivnost/preventivne-prehliadky/vypocet/menovatel',
          vysledok:
            '/hodnotiace-parametre/efektivnost/preventivne-prehliadky/vypocet/vysledok',
        },
      },
      'prev-vas-dp': {
        popis:
          '/hodnotiace-parametre/efektivnost/preventivne-prehliadky-doverapomaha/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/efektivnost/preventivne-prehliadky-doverapomaha/texty/staticky-obsah',
        'vldd-extra':
          '/hodnotiace-parametre/efektivnost/preventivne-prehliadky-doverapomaha/texty/extra-vldd',
        vypocet: {
          citatel:
            '/hodnotiace-parametre/efektivnost/preventivne-prehliadky-doverapomaha/vypocet/citatel',
          menovatel:
            '/hodnotiace-parametre/efektivnost/preventivne-prehliadky-doverapomaha/vypocet/menovatel',
          vysledok:
            '/hodnotiace-parametre/efektivnost/preventivne-prehliadky-doverapomaha/vypocet/vysledok',
        },
      },
      'prev-vas-rok': {
        popis:
          '/hodnotiace-parametre/efektivnost/preventivne-prehliadky-vseobecna-starostlivost/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/efektivnost/preventivne-prehliadky-vseobecna-starostlivost/texty/staticky-obsah',
        vypocet: {
          citatel:
            '/hodnotiace-parametre/efektivnost/preventivne-prehliadky-vseobecna-starostlivost/vypocet/pocet-pacientov',
          menovatel:
            '/hodnotiace-parametre/efektivnost/preventivne-prehliadky-vseobecna-starostlivost/vypocet/celkovy-pocet',
          vysledok:
            '/hodnotiace-parametre/efektivnost/preventivne-prehliadky-vseobecna-starostlivost/vypocet/vysledok',
        },
      },
      'prev-gyn-cyt': {
        popis:
          '/hodnotiace-parametre/efektivnost/cytologicke-vysetrenie-pocas-preventivnej-prehliadky/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/efektivnost/cytologicke-vysetrenie-pocas-preventivnej-prehliadky/texty/staticky-obsah',
        vypocet: {
          vysledok:
            '/hodnotiace-parametre/efektivnost/cytologicke-vysetrenie-pocas-preventivnej-prehliadky/vypocet/vysledok',
          citatel:
            '/hodnotiace-parametre/efektivnost/cytologicke-vysetrenie-pocas-preventivnej-prehliadky/vypocet/citatel',
          menovatel:
            '/hodnotiace-parametre/efektivnost/cytologicke-vysetrenie-pocas-preventivnej-prehliadky/vypocet/menovatel',
        },
      },
      'nakl-sas': {
        popis:
          '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/texty/staticky-obsah',
        vypocet: {
          vysledok:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/vypocet/vysledok',
          NaklSVLZ:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/vypocet/svlz-vysetrenia',
          NaklVPoiD:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/vypocet/2',
          AvgInd:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/vypocet/index-rizika',
          hodnotaCitatel:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/vypocet/celkove-naklady',
          hodnotaMenovatel:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/vypocet/pocet-rizikovych-pacientov',
          NaklVYK:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/vypocet/naklady',
          NaklVYKV:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/vypocet/naklady-vami-realizovane-vysetrenia',
          NaklVYKO:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/vypocet/naklady-vami-indikovane-vysetrenia',
          NaklVYKS:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/vypocet/naklady-vylucene-vykony',
          NaklLAB:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/vypocet/svlz-vysetrenia',
          NaklLABV:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/vypocet/svlz-vami-indikovane-vysetrenia',
          NaklLABO:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/vypocet/svlz-vysetrenia-indikovane-inym',
          NaklLABS:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/vypocet/svlz-vylucene-vykony',
          NaklLEK:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/vypocet/lieky-a-zdravotnicke-pomocky',
          NaklLEKV:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/vypocet/lieky-vami-predpisane-lieky',
          NaklLEKO:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/vypocet/lieky-predpisane-inym',
          NaklLEKS:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/vypocet/lieky-vylucene-lieky',
          NaklLAM:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/vypocet/podane-lieky',
          NaklLAMV:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/vypocet/podane-lieky-vami-podane',
          NaklLAMO:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/vypocet/podane-lieky-na-odporucanie',
          NaklLAMS:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/vypocet/podane-lieky-vylucene',
          PocPoi:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/vypocet/pocet-pacientov',
        },
        tooltipy: {
          NaklVYK:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/tooltipy/naklady-na-vykony',
          NaklLAB:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/tooltipy/svlz-vysetrenia',
          NaklVPoiD:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-sas/tooltipy/2',
        },
      },
      'nakl-vas': {
        popis:
          '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas/texty/staticky-obsah',
        vypocet: {
          vysledok:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas/vypocet/vysledok',
          hodnotaCitatel:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas/vypocet/celkove-naklady',
          hodnotaMenovatel:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas/vypocet/pocet-rizikovych-pacientov',
          PocPoi:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas/vypocet/pocet-pacientov',
          NaklVYK:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas/vypocet/laboratorne-vysetrenia',
          NaklLAB:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas/vypocet/svlz-vysetrenia',
          NaklLABV:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas/vypocet/svlz-vami-indikovane-vysetrenia',
          NaklLABO:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas/vypocet/svlz-vysetrenia-indikovane-inym',
          NaklLABS:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas/vypocet/svlz-vylucene-vykony',
          NaklLEK:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas/vypocet/lieky-a-zdravotnicke-pomocky',
          NaklLEKV:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas/vypocet/lieky-vami-predpisane-lieky',
          NaklLEKO:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas/vypocet/lieky-predpisane-inym',
          NaklLEKS:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas/vypocet/lieky-vylucene-lieky',
          NaklVPoiD:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas/vypocet/naklady',
          AvgInd:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas/vypocet/index-rizika',
        },
      },
      'nakl-vas-dp': {
        popis:
          '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas-doverapomaha/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas-doverapomaha/texty/staticky-obsah',
        vypocet: {
          vysledok:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas-doverapomaha/vypocet/vysledok',
          hodnotaCitatel:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas-doverapomaha/vypocet/celkove-naklady',
          hodnotaMenovatel:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas-doverapomaha/vypocet/pocet-rizikovych-pacientov',
          PocPoi:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas-doverapomaha/vypocet/pocet-pacientov',
          NaklVYK:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas-doverapomaha/vypocet/laboratorne-vysetrenia',
          NaklLAB:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas-doverapomaha/vypocet/svlz-vysetrenia',
          NaklLABV:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas-doverapomaha/vypocet/svlz-vami-indikovane-vysetrenia',
          NaklLABO:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas-doverapomaha/vypocet/svlz-vysetrenia-indikovane-inym',
          NaklLABS:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas-doverapomaha/vypocet/svlz-vylucene-vykony',
          NaklLEK:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas-doverapomaha/vypocet/lieky-a-zdravotnicke-pomocky',
          NaklLEKV:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas-doverapomaha/vypocet/lieky-vami-predpisane-lieky',
          NaklLEKO:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas-doverapomaha/vypocet/lieky-predpisane-inym',
          NaklLEKS:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas-doverapomaha/vypocet/lieky-vylucene-lieky',
          NaklVPoiD:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas-doverapomaha/vypocet/2',
          AvgInd:
            '/hodnotiace-parametre/efektivnost/naklady-na-pacienta-vas-doverapomaha/vypocet/index-rizika',
        },
      },
      'kont-sas': {
        popis: '/hodnotiace-parametre/efektivnost/kontakt-sas/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/efektivnost/kontakt-sas/texty/staticky-obsah',
        statickyObsahNove:
          '/hodnotiace-parametre/efektivnost/kontakt-sas/texty/staticky-obsah-nove-obdobie',
        vypocet: {
          KontPov:
            '/hodnotiace-parametre/efektivnost/kontakt-sas/vypocet/pocet-osetreni',
          KontVVyk:
            '/hodnotiace-parametre/efektivnost/kontakt-sas/vypocet/pocet-vysetreni-np',
          KontVPoiN:
            '/hodnotiace-parametre/efektivnost/kontakt-sas/vypocet/vylucene-vykony',
          KontVPoiD: '/hodnotiace-parametre/efektivnost/kontakt-sas/vypocet/2',
          hodnotaCitatel:
            '/hodnotiace-parametre/efektivnost/kontakt-sas/vypocet/vysledny-pocet-osetreni',
          citatel:
            '/hodnotiace-parametre/efektivnost/kontakt-sas/vypocet/pocet-osetrenych-pacientov',
          AvgInd:
            '/hodnotiace-parametre/efektivnost/kontakt-sas/vypocet/index-rizika',
          hodnotaMenovatel:
            '/hodnotiace-parametre/efektivnost/kontakt-sas/vypocet/pocet-rizikovych-pacientov',
          vysledok:
            '/hodnotiace-parametre/efektivnost/kontakt-sas/vypocet/vysledok',
        },
        tooltipy: {
          KontVVyk:
            '/hodnotiace-parametre/efektivnost/kontakt-sas/tooltipy/pocet-vysetreni',
          KontVPoiN:
            '/hodnotiace-parametre/efektivnost/kontakt-sas/tooltipy/vylucene-vykony',
          KontVPoiNActual:
            '/hodnotiace-parametre/efektivnost/kontakt-sas/tooltipy/vylucene-vykony-202010',
        },
      },
      'starost-vas': {
        popis:
          '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta/texty/staticky-obsah',
        vypocet: {
          vysledok:
            '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta/vypocet/vysledok',
          hodnotaCitatel:
            '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta/vypocet/vysledny-pocet-osetreni',
          hodnotaMenovatel:
            '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta/vypocet/pocet-rizikovych-pacientov',
          KontSAS:
            '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta/vypocet/pocet-kontaktov-sas',
          KontAPS:
            '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta/vypocet/pocet-kontaktov-aps',
          KontUPS:
            '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta/vypocet/pocet-kontaktov-ups',
          KontP:
            '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta/vypocet/pocet-kontaktov-vyluceni',
          AvgInd:
            '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta/vypocet/index-rizika',
          PocPoi:
            '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta/vypocet/pocet-kapitovanych-pacientov',
        },
        tooltipy: {
          PocPoi:
            '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta/tooltipy/pocet-kapitovanych-pacientov',
        },
      },
      'starost-vas-dp': {
        popis:
          '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta-doverapomaha/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta-doverapomaha/texty/staticky-obsah',
        vypocet: {
          vysledok:
            '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta-doverapomaha/vypocet/vysledok',
          hodnotaCitatel:
            '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta-doverapomaha/vypocet/pocet-osetreni',
          hodnotaMenovatel:
            '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta-doverapomaha/vypocet/pocet-rizikovych-pacientov',
          KontSAS:
            '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta-doverapomaha/vypocet/pocet-kontaktov-sas',
          KontAPS:
            '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta-doverapomaha/vypocet/pocet-kontaktov-amb-ps',
          KontUPS:
            '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta-doverapomaha/vypocet/pocet-kontaktov-ps',
          KontP:
            '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta-doverapomaha/vypocet/pocet-kontaktov-vyluceni',
          AvgInd:
            '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta-doverapomaha/vypocet/index-rizika',
          PocPoi:
            '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta-doverapomaha/vypocet/pocet-pacientov',
        },
        tooltipy: {
          PocPoi:
            '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta-doverapomaha/tooltipy/pocet-pacientov',
        },
      },
      'body-psyd': {
        popis:
          '/hodnotiace-parametre/efektivnost/pocet-bodov-na-poistenca-pri-psychodiagnostike/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/efektivnost/pocet-bodov-na-poistenca-pri-psychodiagnostike/texty/staticky-obsah',
        vypocet: {
          citatel:
            '/hodnotiace-parametre/efektivnost/pocet-bodov-na-poistenca-pri-psychodiagnostike/vypocet/pocet-vykazanych-bodov-v-sledovanom-obdobi',
          hodnota3:
            '/hodnotiace-parametre/efektivnost/pocet-bodov-na-poistenca-pri-psychodiagnostike/vypocet/pocet-vykazanych-bodov-specialistom',
          menovatel:
            '/hodnotiace-parametre/efektivnost/pocet-bodov-na-poistenca-pri-psychodiagnostike/vypocet/menovatel',
          vysledok:
            '/hodnotiace-parametre/efektivnost/pocet-bodov-na-poistenca-pri-psychodiagnostike/vypocet/vysledok',
        },
        tooltipy: {
          citatel:
            '/hodnotiace-parametre/efektivnost/pocet-bodov-na-poistenca-pri-psychodiagnostike/tooltipy/pocet-vykazanych-bodov-v-sledovanom-obdobi',
          menovatel:
            '/hodnotiace-parametre/efektivnost/pocet-bodov-na-poistenca-pri-psychodiagnostike/tooltipy/menovatel',
        },
      },
      'vys-psyd': {
        popis:
          '/hodnotiace-parametre/efektivnost/pocet-vysetreni-na-poistenca-pri-psychodiagnostike/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/efektivnost/pocet-vysetreni-na-poistenca-pri-psychodiagnostike/texty/staticky-obsah',
        vypocet: {
          vysledok:
            '/hodnotiace-parametre/efektivnost/pocet-vysetreni-na-poistenca-pri-psychodiagnostike/vypocet/vysledok',
          citatel:
            '/hodnotiace-parametre/efektivnost/pocet-vysetreni-na-poistenca-pri-psychodiagnostike/vypocet/pocet-vysetreni',
          menovatel:
            '/hodnotiace-parametre/efektivnost/pocet-vysetreni-na-poistenca-pri-psychodiagnostike/vypocet/pocete-pacientov',
        },
        tooltipy: {
          citatel:
            '/hodnotiace-parametre/efektivnost/pocet-vysetreni-na-poistenca-pri-psychodiagnostike/tooltipy/pocet-vysetreni',
          menovatel:
            '/hodnotiace-parametre/efektivnost/pocet-vysetreni-na-poistenca-pri-psychodiagnostike/tooltipy/pocete-pacientov',
        },
      },
      'body-psyt': {
        popis:
          '/hodnotiace-parametre/efektivnost/pocet-bodov-na-poistenca-pri-psychoterapii/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/efektivnost/pocet-bodov-na-poistenca-pri-psychoterapii/texty/staticky-obsah',
        vypocet: {
          citatel:
            '/hodnotiace-parametre/efektivnost/pocet-bodov-na-poistenca-pri-psychoterapii/vypocet/pocet-vykazanych-bodov-v-seldovanom-obdobi',
          hodnota3:
            '/hodnotiace-parametre/efektivnost/pocet-bodov-na-poistenca-pri-psychoterapii/vypocet/pocet-vykazanych-bodov-specialistom',
          menovatel:
            '/hodnotiace-parametre/efektivnost/pocet-bodov-na-poistenca-pri-psychoterapii/vypocet/menovatel',
          vysledok:
            '/hodnotiace-parametre/efektivnost/pocet-bodov-na-poistenca-pri-psychoterapii/vypocet/vysledok',
        },
        tooltipy: {
          citatel:
            '/hodnotiace-parametre/efektivnost/pocet-bodov-na-poistenca-pri-psychoterapii/tooltipy/pocet-vykazanych-bodov-v-seldovanom-obdobi',
          menovatel:
            '/hodnotiace-parametre/efektivnost/pocet-bodov-na-poistenca-pri-psychoterapii/tooltipy/menovatel',
        },
      },
      'vys-psyt': {
        popis:
          '/hodnotiace-parametre/efektivnost/pocet-vysetreni-na-poistenca-pri-psychoterapii/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/efektivnost/pocet-vysetreni-na-poistenca-pri-psychoterapii/texty/staticky-obsah',
        vypocet: {
          vysledok:
            '/hodnotiace-parametre/efektivnost/pocet-vysetreni-na-poistenca-pri-psychoterapii/vypocet/vysledok',
          citatel:
            '/hodnotiace-parametre/efektivnost/pocet-vysetreni-na-poistenca-pri-psychoterapii/vypocet/pocet-vysetreni',
          menovatel:
            '/hodnotiace-parametre/efektivnost/pocet-vysetreni-na-poistenca-pri-psychoterapii/vypocet/pocete-pacientov',
        },
        tooltipy: {
          citatel:
            '/hodnotiace-parametre/efektivnost/pocet-vysetreni-na-poistenca-pri-psychoterapii/tooltipy/pocet-vysetreni',
          menovatel:
            '/hodnotiace-parametre/efektivnost/pocet-vysetreni-na-poistenca-pri-psychoterapii/tooltipy/pocete-pacientov',
        },
      },
      'kont-gyn': {
        popis: '/hodnotiace-parametre/efektivnost/kontakt-gyn/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/efektivnost/kontakt-gyn/texty/staticky-obsah',
        vypocet: {
          vysledok:
            '/hodnotiace-parametre/efektivnost/kontakt-gyn/vypocet/vysledok',
          citatel:
            '/hodnotiace-parametre/efektivnost/kontakt-gyn/vypocet/citatel',
          menovatel:
            '/hodnotiace-parametre/efektivnost/kontakt-gyn/vypocet/menovatel',
        },
      },
      'svlz-gyn': {
        popis:
          '/hodnotiace-parametre/efektivnost/vysetrenia-svalz-gyn/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/efektivnost/vysetrenia-svalz-gyn/texty/staticky-obsah',
        vypocet: {
          vysledok:
            '/hodnotiace-parametre/efektivnost/vysetrenia-svalz-gyn/vypocet/vysledok',
          citatel:
            '/hodnotiace-parametre/efektivnost/vysetrenia-svalz-gyn/vypocet/medzisucet',
          menovatel:
            '/hodnotiace-parametre/efektivnost/vysetrenia-svalz-gyn/vypocet/medzisucet',
          BodyVO:
            '/hodnotiace-parametre/efektivnost/vysetrenia-svalz-gyn/vypocet/pocet-bodov',
          BodySD:
            '/hodnotiace-parametre/efektivnost/vysetrenia-svalz-gyn/vypocet/diagnozy',
          BodySR:
            '/hodnotiace-parametre/efektivnost/vysetrenia-svalz-gyn/vypocet/produkty',
          BodySP:
            '/hodnotiace-parametre/efektivnost/vysetrenia-svalz-gyn/vypocet/vysetrenia',
          BodyI:
            '/hodnotiace-parametre/efektivnost/vysetrenia-svalz-gyn/vypocet/vykony',
          PoiVO:
            '/hodnotiace-parametre/efektivnost/vysetrenia-svalz-gyn/vypocet/pocet-pacientiek',
          PoiSD:
            '/hodnotiace-parametre/efektivnost/vysetrenia-svalz-gyn/vypocet/diagnozy',
          PoiSR:
            '/hodnotiace-parametre/efektivnost/vysetrenia-svalz-gyn/vypocet/produkty',
          PoiSP:
            '/hodnotiace-parametre/efektivnost/vysetrenia-svalz-gyn/vypocet/vysetrenia',
          hodnotaCitatel:
            '/hodnotiace-parametre/efektivnost/vysetrenia-svalz-gyn/vypocet/pocet-bodov',
          hodnotaMenovatel:
            '/hodnotiace-parametre/efektivnost/vysetrenia-svalz-gyn/vypocet/pocet-pacientiek',
        },
      },
      'body-log': {
        popis:
          '/hodnotiace-parametre/efektivnost/pocet-bodov-na-poistenca/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/efektivnost/pocet-bodov-na-poistenca/texty/staticky-obsah',
        vypocet: {
          vysledok:
            '/hodnotiace-parametre/efektivnost/pocet-bodov-na-poistenca/vypocet/vysledok',
          citatel:
            '/hodnotiace-parametre/efektivnost/pocet-bodov-na-poistenca/vypocet/pocet-vykazanych-bodov',
          hodnota3:
            '/hodnotiace-parametre/efektivnost/pocet-bodov-na-poistenca/vypocet/pocet-bodov-vykonov',
          menovatel:
            '/hodnotiace-parametre/efektivnost/pocet-bodov-na-poistenca/vypocet/pocet-pacientov',
        },
        tooltipy: {
          menovatel:
            '/hodnotiace-parametre/efektivnost/pocet-bodov-na-poistenca/tooltipy/pocet-pacientov',
        },
      },
      'vys-log': {
        popis:
          '/hodnotiace-parametre/efektivnost/pocet-vysetreni-na-poistenca/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/efektivnost/pocet-vysetreni-na-poistenca/texty/staticky-obsah',
        vypocet: {
          vysledok:
            '/hodnotiace-parametre/efektivnost/pocet-vysetreni-na-poistenca/vypocet/vysledok',
          citatel:
            '/hodnotiace-parametre/efektivnost/pocet-vysetreni-na-poistenca/vypocet/celkovy-pocet-vysetreni',
          menovatel:
            '/hodnotiace-parametre/efektivnost/pocet-vysetreni-na-poistenca/vypocet/pocet-pacientov',
        },
      },
      tcc: {
        popis:
          '/hodnotiace-parametre/efektivnost/total-costs-of-care/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/efektivnost/total-costs-of-care/texty/staticky-obsah',
        vypocet: {
          vysledok:
            '/hodnotiace-parametre/efektivnost/total-costs-of-care/vypocet/vysledok',
          NaklZS:
            '/hodnotiace-parametre/efektivnost/total-costs-of-care/vypocet/celkove-naklady',
          hodnotaCitatel:
            '/hodnotiace-parametre/efektivnost/total-costs-of-care/vypocet/celkove-naklady',
          hodnotaMenovatel:
            '/hodnotiace-parametre/efektivnost/total-costs-of-care/vypocet/pocet-rizikovych-pacientov',
          NaklP:
            '/hodnotiace-parametre/efektivnost/total-costs-of-care/vypocet/2',
          PocPoi:
            '/hodnotiace-parametre/efektivnost/total-costs-of-care/vypocet/pocet-pacientov',
          AvgInd:
            '/hodnotiace-parametre/efektivnost/total-costs-of-care/vypocet/index-rizika',
        },
      },
      'bolest-chrb': {
        popis:
          '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta-s-bolestou-chrbta/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta-s-bolestou-chrbta/texty/staticky-obsah',
        vypocet: {
          citatel:
            '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta-s-bolestou-chrbta/vypocet/citatel',
          menovatel:
            '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta-s-bolestou-chrbta/vypocet/menovatel',
          vysledok:
            '/hodnotiace-parametre/efektivnost/starostlivost-o-pacienta-s-bolestou-chrbta/vypocet/vysledok',
        },
      },
    },
    Inovácie: {
      erec: {
        popis: '/hodnotiace-parametre/inovacie/erecept/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/inovacie/erecept/texty/staticky-obsah',
        vypocet: {
          citatel: '/hodnotiace-parametre/inovacie/erecept/vypocet/citatel',
          menovatel: '/hodnotiace-parametre/inovacie/erecept/vypocet/menovatel',
          vysledok: '/hodnotiace-parametre/inovacie/erecept/vypocet/vysledok',
        },
      },
      ezuc: {
        popis: '/hodnotiace-parametre/inovacie/ezuctovanie/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/inovacie/ezuctovanie/texty/staticky-obsah',
        vypocet: {
          citatel: '/hodnotiace-parametre/inovacie/ezuctovanie/vypocet/citatel',
          menovatel:
            '/hodnotiace-parametre/inovacie/ezuctovanie/vypocet/menovatel',
          vysledok:
            '/hodnotiace-parametre/inovacie/ezuctovanie/vypocet/vysledok',
        },
      },
      enav: {
        popis: '/hodnotiace-parametre/inovacie/navrhyonline/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/inovacie/navrhyonline/texty/staticky-obsah',
        vypocet: {
          citatel:
            '/hodnotiace-parametre/inovacie/navrhyonline/vypocet/citatel',
          menovatel:
            '/hodnotiace-parametre/inovacie/navrhyonline/vypocet/menovatel',
          vysledok:
            '/hodnotiace-parametre/inovacie/navrhyonline/vypocet/vysledok',
        },
      },
      elab: {
        popis: '/hodnotiace-parametre/inovacie/elab/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/inovacie/elab/texty/staticky-obsah',
        vypocet: {
          citatel: '/hodnotiace-parametre/inovacie/elab/vypocet/citatel',
          menovatel: '/hodnotiace-parametre/inovacie/elab/vypocet/menovatel',
          vysledok: '/hodnotiace-parametre/inovacie/elab/vypocet/vysledok',
        },
      },
      tele: {
        popis:
          '/hodnotiace-parametre/inovacie/poskytovanie-telekonzultacii/texty/popis',
        statickyObsah:
          '/hodnotiace-parametre/inovacie/poskytovanie-telekonzultacii/texty/staticky-obsah',
        vypocet: {
          '1B': '/hodnotiace-parametre/inovacie/poskytovanie-telekonzultacii/vypocet/1b',
          '1C': '/hodnotiace-parametre/inovacie/poskytovanie-telekonzultacii/vypocet/1c',
          '11A':
            '/hodnotiace-parametre/inovacie/poskytovanie-telekonzultacii/vypocet/11a',
          vysledok:
            '/hodnotiace-parametre/inovacie/poskytovanie-telekonzultacii/vypocet/vysledok',
        },
      },
    },
    ZDK: {
      popis: '/hodnotiace-parametre/zaklad-dodatkovej-kapitacie/ppopis',
      'erec-gyn': {
        statickyObsah:
          '/hodnotiace-parametre/zaklad-dodatkovej-kapitacie/erecept/texty/staticky-obsah',
      },
      'pristr-gyn': {
        statickyObsah:
          '/hodnotiace-parametre/zaklad-dodatkovej-kapitacie/pristrojove-vybavenie-gyn/texty/staticky-obsah',
      },
    },
    vymenaParametra: {
      panelVymeny: {
        info: {
          tlacidlo:
            '/hodnotiace-parametre/vymena-parametra/panel-vymeny/info/tlacidlo-akcie',
          readonly:
            '/hodnotiace-parametre/vymena-parametra/panel-vymeny/info/readonly-akcia-nie-je-stat',
          akciaSTATPovodny:
            '/hodnotiace-parametre/vymena-parametra/panel-vymeny/info/akcia-stat-neprebehla-vymena-povodny-hp',
          akciaSTATNovy:
            '/hodnotiace-parametre/vymena-parametra/panel-vymeny/info/akcia-stat-neprebehla-vymena-novy-hp',
          ukoncenieAkciePovodny:
            '/hodnotiace-parametre/vymena-parametra/panel-vymeny/info/po-ukonceni-akcie-povodny-hp',
          ukoncenieAkcieZvoleny:
            '/hodnotiace-parametre/vymena-parametra/panel-vymeny/info/po-ukonceni-akcie-zvoleny-hp',
        },
        error: {
          tlacidlo:
            '/hodnotiace-parametre/vymena-parametra/panel-vymeny/error/tlacidlo-akcie',
          chybajuciSTAT:
            '/hodnotiace-parametre/vymena-parametra/panel-vymeny/error/chybajuci-stat',
        },
        success: {
          nepocitaHP:
            '/hodnotiace-parametre/vymena-parametra/panel-vymeny/success/po-vymene-hp-sa-nepocita-do-obdobia',
          pocitaHP:
            '/hodnotiace-parametre/vymena-parametra/panel-vymeny/success/po-vymene-hp-sa-pocita-do-obdobia',
        },
      },
      modalVymeny: {
        btnZrusit:
          '/hodnotiace-parametre/vymena-parametra/modal-vymeny/tlacidlo-zrusit',
        btnSubmit:
          '/hodnotiace-parametre/vymena-parametra/modal-vymeny/tlacidlo-potvrdit',
        valid:
          '/hodnotiace-parametre/vymena-parametra/modal-vymeny/platnost-do',
        description:
          '/hodnotiace-parametre/vymena-parametra/modal-vymeny/popis',
        title: '/hodnotiace-parametre/vymena-parametra/modal-vymeny/nadpis',
      },
    },
  },
  poistenci: {
    tipy: {
      nadpis: '/poistenci/kapitovani-a-sporni/tipy/nadpis',
      popis: '/poistenci/kapitovani-a-sporni/tipy/popis',
    },
  },
  autentifikacia: {
    banery: {
      dashboardBanner: {
        verziaA: '/autentifikacia/banery/dashboard-baner/verzia-a',
        verziaB: '/autentifikacia/banery/dashboard-baner/verzia-b',
        verziaBHidden:
          '/autentifikacia/banery/dashboard-baner/verzia-b-skryty-obsah',
      },
      dashboardKarta: {
        nadpis: '/autentifikacia/banery/dashboard-karta/nadpis',
        popis: '/autentifikacia/banery/dashboard-karta/popis',
        tlacidlo: '/autentifikacia/banery/dashboard-karta/tlacidlo',
      },
      banerPacienti: {
        nadpis: '/autentifikacia/banery/baner-pacienti/nadpis',
        popis: '/autentifikacia/banery/baner-pacienti/popis',
        tlacidlo: '/autentifikacia/banery/baner-pacienti/tlacidlo',
      },
    },
    hlasky: {
      odoslanie: {
        uspesne: '/autentifikacia/hlasky/odoslanie/uspesne',
        uspesneSchvalenie:
          '/autentifikacia/hlasky/odoslanie/uspesne-schvalenie',
        neuspesne: '/autentifikacia/hlasky/odoslanie/neuspesne',
        neuspesneSchvalenie:
          '/autentifikacia/hlasky/odoslanie/neuspesne-schvalenie',
        neuspesnaPersonalizacia:
          '/autentifikacia/hlasky/odoslanie/neuspesna-personalizacia',
        upozornenie: '/autentifikacia/hlasky/odoslanie/upozornenie',
        upozornenieHK: '/autentifikacia/hlasky/odoslanie/upozornenie-hk',
        upozornenieSchvalenie:
          '/autentifikacia/hlasky/odoslanie/upozornenie-pri-schvaleni',
      },
      info: {
        rezervovanySTAT:
          '/autentifikacia/hlasky/info/rezervovany-stat-pre-ucet',
        dalsiePozicie: '/autentifikacia/hlasky/info/dalsie-pozicie',
      },
      upozornenie: {
        heslo: '/autentifikacia/hlasky/upozornenie/heslo',
      },
      chybove: {
        ucetSTATVytvoreny:
          '/autentifikacia/hlasky/chybove/ucet-stat-bol-vytvoreny',
        defaultSTAT: '/autentifikacia/hlasky/chybove/default-chyba-stat',
        LEKInyKodInput: '/autentifikacia/hlasky/chybove/lek-iny-kod-input',
        STATNezhodaLEKInput:
          '/autentifikacia/hlasky/chybove/stat-sa-nezhoduje-s-lek-input',
        defaultLEK: '/autentifikacia/hlasky/chybove/default-chyba-lek',
        LEKPriradenyInyUcet:
          '/autentifikacia/hlasky/chybove/lek-je-priradeny-k-inemu-uctu',
        LEKVytvoreny: '/autentifikacia/hlasky/chybove/ucet-lek-bol-vytvoreny',
        rozdielnyLekStat: '/autentifikacia/hlasky/chybove/rozdielny-lek-stat',
        neevidujemeUvazok: '/autentifikacia/hlasky/chybove/neevidujeme-uvazok',
        pozicia: '/autentifikacia/hlasky/chybove/vasa-pozicia',
      },
    },
  },
  proposals: {
    newProposal: {
      modals: {
        reasonsModal: {
          title: '',
          description: '',
          reasons: {
            EsteJePriskoro: '',
            UzJeNeskoro: '',
            IsVekPoi: '',
            NedostPocetNavstSustLiecbu: '',
            NenajdenaHospitalizacia: '',
            NenajdenaOperacia: '',
            ExistSchvaleneRozhodnutie: '',
            NedostPocetNavstChronik: '',
            CerpanieKNsPeriod: '',
            SVLZ: '',
            CerpanieKNsJednoraz: '',
            Vysetrenia: '',
            Kontraindikacie: '',
            Potvrdenie70R: '',
            Dlznik: '',
          },
        },
      },
      controls: {
        EsteJePriskoro: '',
        UzJeNeskoro: '',
        IsVekPoi: '',
        NedostPocetNavstSustLiecbu: '',
        NenajdenaHospitalizacia: '',
        NenajdenaOperacia: '',
        ExistSchvaleneRozhodnutie: '',
        NedostPocetNavstChronik: '',
        CerpanieKNsPeriod: '',
        SVLZ: '',
        CerpanieKNsJednoraz: '',
        Vysetrenia: '',
        Kontraindikacie: '',
        Potvrdenie70R: '',
        Dlznik: '',
        NeaktivnyPOI: '',
      },
    },
    videomanual: {
      btn: '/navrhy/novy-navrh/videomanual/tlacidlo',
      description: '/navrhy/novy-navrh/videomanual/popis',
      videoTitle: '/navrhy/novy-navrh/videomanual/nadpis-videa',
    },
    common: {
      indicatorListUrl: '/navrhy/spolocne/link-na-indikacny-zoznam',
    },
  },
  messages: {
    requiredAttachment: '/schranka-sprav/povinna-priloha',
  },
  pcp: {
    title: '/manazersky-prehlad-cenovych-podmienok/nadpis',
    description: '/manazersky-prehlad-cenovych-podmienok/popis',
  },
  cakacky: {
    manual: {
      btn: '/cakacie-listiny/manual/tlacidlo',
      description: '/cakacie-listiny/manual/popis',
      link: '/cakacie-listiny/manual/link',
    },
  },
  zuctovanie: {
    faktury: {
      videomanual: {
        btn: '/zuctovanie/faktury/videomanual/tlacidlo',
        description: '/zuctovanie/faktury/videomanual/popis',
      },
    },
  },
  references: {
    all: '/referencie',
    count: '/referencie/systemove-parametre/zobrazeny-pocet',
  },
};
