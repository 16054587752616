import dayjs, { UnitType } from 'dayjs';
import 'dayjs/locale/sk';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import strings from '../constants/strings';
import moment from 'moment';

dayjs.locale('sk');
dayjs.extend(customParseFormat);

export const DATE_FORMAT_ORACLE = 'YYYYMM';
export const DATE_FORMAT_ORACLE_DAY = 'YYYYMMDD';
export const DATE_FORMAT_SLOVAK = 'D. M. YYYY';
export const DATE_FORMAT_MONTH_YEAR = 'MMMM YYYY';
export const DATE_FORMAT_MONTH_YEAR_SHORT = 'MM/YYYY';
export const DATE_TIME_FORMAT = 'D. M. YYYY HH:mm:ss';
export const DATE_TIME_MESSAGE_FORMAT = 'D. M. YYYY, HH:mm';
export const DATE_INTERNATIONAL = 'YYYY-MM-DD';

export const getMoment = (
  date?: Date | string | number | null,
): moment.Moment => {
  if (!date) return moment();
  if (typeof date === 'string' && date.includes('T'))
    return moment(date.split('T')[0]);
  return moment(date);
};

export function formatDate(
  date: string | number | Date | undefined | null,
  format: string = DATE_FORMAT_SLOVAK,
  withUtc: boolean = false,
): string {
  if (!date) return strings.undefined;
  if (date.toString().startsWith('0001')) return ' - ';
  if (date.toString().length === 6)
    return dayjs(date.toString()).format(format);
  if (format.includes('HH') && !withUtc)
    return moment(date).utcOffset(0, false).format(format);
  return date ? getMoment(date).format(format) : ' - ';
}

export function endOf(
  date: string | number | undefined,
  unit: UnitType = 'month',
): number | string {
  return getMoment(date).endOf(unit).format('D');
}

export function daysLeft(date: string | number | undefined) {
  return getMoment(getMoment(date).format('YYYY-MM-DD')).diff(
    getMoment(getMoment().format('YYYY-MM-DD')),
    'days',
  );
}

export const formatHours = (str: string): string => {
  if (!str) return '-';
  return moment(str, 'HHmmss').format('H:mm');
};

export const calculateAge = (dateOfBirth?: string): number => {
  if (!dateOfBirth) return 0;

  const birthDate = moment(dateOfBirth, 'YYYY-MM-DD');
  const today = moment();

  return today.diff(birthDate, 'years');
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const formatDateTime = (date: Date, format?: string) => {
  if (!date) return '';
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

export const periodShapeDate = (date?: string | number): string => {
  if (!date) return strings.undefined;
  const d = formatDate(date.toString(), DATE_FORMAT_MONTH_YEAR);
  if (!/(.){0,500}\s\d{4}/.test(d)) return strings.undefined;
  const month = d.split(' ')[0];
  const year = d.split(' ')?.[1] || '';
  switch (month) {
    case 'január':
    case 'február':
    case 'apríl':
    case 'máj':
    case 'jún':
    case 'júl':
    case 'august':
      return `${month}a ${year}`;
    case 'marec':
      return `marca ${year}`;
    default:
      return `${month.split('er')[0]}ra ${year}`;
  }
};

export const getMonthsBefore = (date: string): string => {
  let months = 0;
  let years = 0;
  if (date) {
    months = moment().diff(date, 'M');
    years = moment().diff(date, 'y');
  }
  let str = months === 1 ? '1 mesiacom' : `${months} mesiacmi`;
  if (years >= 3) str = `${years} rokmi`;
  return `pred ${str}`;
};

export const getFirstDateInMonthFromPeriod = (periodProp: number): string => {
  const period = periodProp.toString();
  if (/\d+/.test(period) && period.length === 6) {
    const year: string = period.substring(0, 4);
    const month: string = period.substring(4, 6);
    return `${year}-${month}-01`;
  }
  return moment().format(DATE_INTERNATIONAL);
};

export const getLastDateInMonthFromPeriod = (periodProp: number): string => {
  const period = periodProp.toString();
  if (/\d+/.test(period) && period.length === 6) {
    const year: string = period.substring(0, 4);
    const month: string = period.substring(4, 6);
    const day: string = endOf(`${year}-${month}-01`).toString();
    const lastDate = `${year}-${month}-${day}`;
    return moment() > moment(lastDate)
      ? lastDate
      : moment().format(DATE_INTERNATIONAL);
  }
  return moment().format(DATE_INTERNATIONAL);
};

export const getDateFromOracle = ({
  alt,
  date,
}: {
  alt: string;
  date?: number | string;
}): string => {
  if (!date || !moment(date, DATE_FORMAT_ORACLE_DAY).isValid()) return alt;
  return moment(date, DATE_FORMAT_ORACLE_DAY).format(DATE_INTERNATIONAL);
};
