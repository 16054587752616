import { Icon, Tooltip } from '@dovera/design-system';
import useStyles from '../../styles/detail.styles';

interface Props {
  title: string;
  tooltip: string;
  value: string;
}

const AdditionalText = ({ title, tooltip, value }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.graphInfo}>
      {title} <span className="text-color-black">{value}</span>
      <Tooltip dialog={tooltip}>
        <Icon name="info" />
      </Tooltip>
    </div>
  );
};

export default AdditionalText;
